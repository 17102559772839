const initState =
{
  master              : false,
  verified            : false,

  reportSnapshot      : null,
}

const reportReducer = (state = initState, action) =>
{
  switch (action.type)
  {    
    case 'SET_REPORT_MASTER':
      return {
        ...state,
        master: action.master
      };

    case 'SET_REPORT_VERIFIED':
      return {
        ...state,
        verified: action.verified
      };

    case 'SET_REPORT_SNAPSHOT':
      return {
        ...state,
        reportSnapshot: action.reportSnapshot
      };
    
    default:
      return state; 
  }
}

export default reportReducer;