//Dependencies
import React, { useEffect, useCallback, useState } from 'react';
import { Map, TileLayer, Marker, CircleMarker } from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-markercluster";
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Icon } from "leaflet";

require('react-leaflet-markercluster/dist/styles.min.css');

//https://stackoverflow.com/questions/37166172/mapbox-tiles-and-leafletjs

const markerBlue = new Icon(
  {
    iconUrl: 'https://firebasestorage.googleapis.com/v0/b/bettercityio.appspot.com/o/markers%2Fmarker09.png?alt=media&token=1b29ff2d-639c-4075-a864-c3e57d4332aa',
    //shadowUrl: 'https://firebasestorage.googleapis.com/v0/b/bettercityio.appspot.com/o/markers%2Fshadow01.png?alt=media&token=55f814fb-eacc-4395-a0fc-db2ae9a767d4',
    iconSize: [40, 40],
    iconAnchor: [20, 40],
    popupAnchor: [1, -34],
    //shadowSize: [40, 40]
  });

const MapPage01 = (props) =>
{
  const dispatch = useDispatch();
  const navigate = useNavigate(); 

  const { group, last, layers, location, center, filter, height, markers, markerList01, paddingBottom, paddingTop, zoom } = props;

  const [ markerList02, setMarkerList02 ] = useState(null);
  
  const layer1 = "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}";
  const layer2 = "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png";

  const clickMarker = useCallback((info) => 
  {
    navigate(`/${info.id}`); 
  }, [navigate]); 

  const dragMap = useCallback((e) => 
  {
    dispatch({type: 'SET_CENTER', center : e.target.getCenter()});
  }, [dispatch]); 
 
  /* FILTERS */
  useEffect(() => 
  {
    let markerArray = [];

    try
    {
      markerArray = Object.keys(markers).map((m1, i) =>
      {
        let info = {...markers[m1]};
        let reportsLength = Object.keys(info.reports).length;
        let lastReport = Object.keys(info.reports).find(f1 => info.reports[f1].id === reportsLength);

       // let markerColor = info.reports[lastReport].type === 'damage' || info.reports[lastReport].type === 'update' ? '#f2261c' : '#1cf244';

        let markerColor;

        switch(info.reports[lastReport].type) 
        {
          case 'damage':
            markerColor = '#f2261c'
            break;
          case 'update':
            markerColor = '#FFD966'
            break;
          default:
            markerColor = '#1cf244'
        }

        let marker = 
          <CircleMarker
            key         = {i}
            center      = {info.location}
            fill        = "true"
            color       = '#1f1f1f'
            fillColor   = {markerColor}
            opacity     = {1}
            fillOpacity = {0.7}
            weight      = {2}

            onClick = {() => clickMarker(info)}
          />

        if(info.damageType === filter)
        {
          return marker;
        }
        else        
        {
          return null;
        }
      });

      setMarkerList02(markerArray);
    } 
    catch (error) 
    {
      console.log(error);
    }    
  }, [clickMarker, filter, markers]);

  const mapZoom = (e) =>
  {
    dispatch({type: 'SET_ZOOM', zoom : e.target.getZoom()});
  }
  
  return(
    <div
      style = {{height : height, width : '100%', paddingTop : paddingTop, paddingBottom : paddingBottom }}
    >
      <Map
        center = {center} 
        zoom = {zoom} 
        style = {{height : '100%', width : '100%'}}
        onzoomend = {(e) => mapZoom(e)} 
        ondragend = {(e) => dragMap(e)}

        minZoom = {5}
        maxZoom = {22}
      >
        <TileLayer
          url = {layers ? layer2 : layer1}
        />
        
        {group === true &&
          <MarkerClusterGroup>
             {!filter && markerList01}
          </MarkerClusterGroup>
        }

        {group === false &&
          !filter && markerList01
        }
        
        {last &&
          <Marker
            position = {last.location}
            icon = {markerBlue}
            onClick = {() => clickMarker(last)}
          />
        }

        {location &&
          <React.Fragment>
             <CircleMarker
              center = {location}
              fill = "true"
              color = '#333'
              fillColor = '#333'
              opacity = {0.3}
              fillOpacity = {0.3}
              weight = {2}
            />

            <CircleMarker
              className = 'bouncingMarker'
              center = {location}
              fill = "true"
              color = '#333'
              fillColor = '#1F51FF'
              opacity = {1}
              fillOpacity = {0.8}
              weight = {2}
            />           
          </React.Fragment>
        }
       
        {filter && markerList02}
      </Map>
    </div>
  );
}

export default MapPage01;