//Dependencies
import React from 'react';
import ProgressiveImage from 'react-progressive-graceful-image';
//Components
import CameraCapture from '../camera/CameraCapture01';

/* DRAWER DE REPORTE - REPORT */
const CameraComponent01 = ({snapshot, webcamRef}) =>
{ 
  return(
    <div className = "w-full bg-white select-none">
      <div className = "flex bg-white"  >
        <div className = "flex items-center flex-col text-center md:w-full sm:w-full w-full" >
          {snapshot &&
            <div className = "">
              <ProgressiveImage 
                src = {snapshot} 
                placeholder = {'https://firebasestorage.googleapis.com/v0/b/bettercityio.appspot.com/o/extra%2Fblurimage.jpg?alt=media&token=1f2ea1d6-8609-4225-abf0-77a31f10b732'}
              >
                {(src) => {
                  return (
                    <img 
                      src = {src} 
                      style = {{ width: window.innerWidth , height: window.innerWidth, maxWidth: window.innerWidth, maxHeight: window.innerWidth, margin: 'auto'}}
                      alt = 'report'
                      className ='shadow-xl'
                    />
                  );
                }}
              </ProgressiveImage>
            </div>
          }

          {!snapshot &&
            <div
              className = ""
            >
              <CameraCapture
                webcamRef     = {webcamRef}
              />
            </div>
          }
        </div>
      </div>
    </div>
  );
}

export default CameraComponent01;