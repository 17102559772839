//Dependencies
import React from 'react';
import { Link } from 'react-router-dom';
import { Popover } from 'antd';
import { EnvironmentOutlined, FilterOutlined, HomeOutlined, HomeFilled } from '@ant-design/icons';
//Components
import Popover03 from '../popovers/Popover03';
import Popover02 from '../popovers/Popover02';

/* MENU BAR */
const Menu01 = (props) =>
{
  const { id, filter, liveLocation, height, visible } = props;
  if(!visible) return null;
  
  return(
    <footer 
      className = {` fixed bottom-0 flex justify-center items-center bg-white text-gray-800 w-screen border-t-2 border-slate-500 drop-shadow-xl z-[1000] `}
      style = {{height : height}}
    >
      <div className = ' w-[350px] flex justify-center items-center h-full '>
        <div className = "flex flex-1 justify-center items-center text-gray-900 hover:text-sky-600">
          <Link 
            className = ' justify-center items-center text-gray-900 cursor-default '
            to        = {'/app'}
          >
            {!id &&
              <HomeFilled className = ' text-3xl lg:text-4xl flex cursor-pointer hover:text-sky-600 '/>
            }

            {id &&
              <HomeOutlined  className = ' text-3xl lg:text-4xl flex cursor-pointer '/>
            }
          </Link>
        </div>

        <div className = "flex flex-1 justify-center items-center text-gray-900 hover:text-sky-600">
          <Popover
            content = 
            {
              () => <Popover03
                      liveLocation = {liveLocation}
                    />
            }
            trigger = "click"
          >
            <div
              className = "flex flex-1 justify-center items-center text-gray-900 hover:text-sky-600 cursor-pointer"
            >
              <EnvironmentOutlined className = " text-3xl lg:text-4xl flex "/>
            </div>
          </Popover>
        </div>

        <div className = "flex flex-1 justify-center items-center text-gray-900 hover:text-sky-600">
          <Popover
            content = 
            {
              () => <Popover02
                      filter = {filter}
                    />
            }
            trigger = "click"
          >
            <div
              className = "flex flex-1 justify-center items-center text-gray-900 hover:text-sky-600 cursor-pointer"
            >
              <FilterOutlined className = " text-3xl lg:text-4xl flex "/>
            </div>
          </Popover>
        </div>
      </div>
    </footer>
  );
}

export default Menu01;