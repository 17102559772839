const { TelegramClient } = require('messaging-api-telegram');
const userID = '1196253376';

///AQUÍ SE ENCUENTRA EL USERIDBOT
//https://www.integromat.com/en/help/app/telegram#User%20ID

//CAMBIA LA IMAGEN DE TU BOT
//https://taradevko.com/bots/configure-telegram-chatbot/

//CAMBIA EL NOMBRE DE TU BOT
//https://chatbotslife.com/your-first-telegram-bot-47ea6034d311

//https://snyk.io/advisor/npm-package/messaging-api-telegram
//https://namespaceit.com/blog/how-fix-breaking-change-webpack-5-used-to-include-polyfills-for-nodejs-core-modules-by-default-error

const client = new TelegramClient({
  accessToken: '1101094243:AAEhLkigmnoBe4SWpQ3QFynG60witIv23Y0',
  onRequest: ({ method, url, headers, body }) => 
  {
   
  },
});

/* LOAD NOTIFICATION */
export const LoadReport = ( id, views, mobile ) => 
{  
  let message = `id: ${id}\nviews: ${views}\nmobile: ${mobile}\npage: www.aportevial.com/${id}`;

  try 
  {    
    client.sendMessage(userID, message).then(() => {
        console.log('sent');
      }); 
  } 
  catch (error) 
  {
    console.log(error);
  };
}

/* CLOSE NOTIFICATION */
export const CloseReport = ( id ) => 
{  
  let message = `id: ${id}\ncerrado`;

  try 
  {    
    client.sendMessage(userID, message).then(() => {
        console.log('sent');
      }); 
  } 
  catch (error) 
  {
    console.log(error);
  };
}

/* UPLOAD NOTIFICATION */
export const UploadAporteNotificacion = ( data ) => 
{
  let message = `user: ${data.user.uid}\nmobile: ${data.user.phoneNumber}\npage: www.aportevial.com/${data.date}`;

  try 
  {    
    client.sendMessage(userID, message).then(() => {
        console.log('sent');
      }); 
  } 
  catch (error) 
  {
    console.log(error);
  };
}