//Dependencies
import React from 'react';
//Ant Design
import Icon from '@ant-design/icons';

//https://iconify.design/
//https://svg2jsx.com/
//https://www.svgviewer.dev/

const LayersFilledeIconSVG = () => 
(
  <svg width = "1.2em" height = "1.2em" fill = "currentColor" viewBox = "0 0 512 512">
    <path d = "M12.41 148.02l232.94 105.67c6.8 3.09 14.49 3.09 21.29 0l232.94-105.67c16.55-7.51 16.55-32.52 0-40.03L266.65 2.31a25.607 25.607 0 0 0-21.29 0L12.41 107.98c-16.55 7.51-16.55 32.53 0 40.04zm487.18 88.28l-58.09-26.33l-161.64 73.27c-7.56 3.43-15.59 5.17-23.86 5.17s-16.29-1.74-23.86-5.17L70.51 209.97l-58.1 26.33c-16.55 7.5-16.55 32.5 0 40l232.94 105.59c6.8 3.08 14.49 3.08 21.29 0L499.59 276.3c16.55-7.5 16.55-32.5 0-40zm0 127.8l-57.87-26.23l-161.86 73.37c-7.56 3.43-15.59 5.17-23.86 5.17s-16.29-1.74-23.86-5.17L70.29 337.87L12.41 364.1c-16.55 7.5-16.55 32.5 0 40l232.94 105.59c6.8 3.08 14.49 3.08 21.29 0L499.59 404.1c16.55-7.5 16.55-32.5 0-40z"/>
  </svg>
);

const InfoFilledeIconSVG = () => 
(
  <svg width = "1.2em" height = "1.2em" fill = "currentColor" viewBox = "0 0 192 512">
    <path d = "M20 424.229h20V279.771H20c-11.046 0-20-8.954-20-20V212c0-11.046 8.954-20 20-20h112c11.046 0 20 8.954 20 20v212.229h20c11.046 0 20 8.954 20 20V492c0 11.046-8.954 20-20 20H20c-11.046 0-20-8.954-20-20v-47.771c0-11.046 8.954-20 20-20zM96 0C56.235 0 24 32.235 24 72s32.235 72 72 72s72-32.235 72-72S135.764 0 96 0z"/>
  </svg>
);

export const LayerFilledIcon = props => <Icon component = {LayersFilledeIconSVG} {...props} />;
export const InfoFilledIcon = props => <Icon component = {InfoFilledeIconSVG} {...props} />;