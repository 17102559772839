import { toastr } from 'react-redux-toastr';
import { ref, getDownloadURL, uploadString } from "firebase/storage";
import { collection, doc, setDoc, increment, updateDoc } from "firebase/firestore"; 
import { storage, db } from '../../config/fbConfig';
//Actions
import { GetMarkersAfterReport } from './LoadingActions';
import { UploadAporteNotificacion } from './NotificationsActions';

export const UploadReport = (data) =>async (dispatch) =>
{ 
  let url = null;
  const document = GetGridDocument(data.location);
  const path = `/reports/${data.date}/`;
 
  try
  {
    /* REPORT LOADING */
    dispatch({type: 'UPLOADING', uploading : true});
    toastr.success("APORTE VIAL", 'REPORTANDO....');
   
    if(data.image)
    {
      const imageRef = ref(storage, path);
            
      await uploadString(imageRef, data.image, 'data_url');

      url = await getDownloadURL(imageRef);

      let newData = 
      {
        id          : data.date,
        location    : data.location,
        reports     :
        {
          report1 :
          {
            id          : 1,
            type        : 'damage',
            image       : url,
            date        : data.date,
            user        : data.user.uid,            
            phoneNumber : data.user.phoneNumber,
          },
        },

        damageType    : data.type,

        verifiedArray : [],
        views         : 0,
        
        approved      : true,
      }

      const reportsRef = collection(db, "markers");

      await setDoc(doc(reportsRef, document), 
      {
        [`${data.date}`] : newData
      }, { merge: true });


      dispatch({type: 'SET_REPORT_SNAPSHOT', reportSnapshot : null});
      dispatch(GetMarkersAfterReport());
      dispatch({type: 'UPLOADING', uploading : false});
    }
    else
    {
      toastr.error("APORTE VIAL", 'FOTO DEL DAÑO ES NECESARIO');
    }    

    toastr.success("APORTE VIAL", 'GRACIAS POR AÑADIR APORTE');
  }
  catch (error)
  {
    console.log(error);
    toastr.error("APORTE VIAL", 'HUBO PROBLEMAS AL SUBIR APORTE');
  } 
}

export const UpdateDamage = (data) =>async (dispatch) =>
{ 
  dispatch({type: 'UPLOADING', uploading : true});
  toastr.success("APORTE VIAL", 'ACTUALIZANDO....');

  const reportsRef = collection(db, "markers");

  let report = data.report;

  let id = report.id;

  let verifiedArray = data.report.verifiedArray;

  if(!data.alreadyVerified)
  {
    verifiedArray.push(data.user.uid);
  }  

  let newData = 
  {
    location    : data.isLocationChange ? data.location : report.location,

    damageType    : data.damageType,
    verifiedArray : data.isVerified ? verifiedArray : data.report.verifiedArray,    
    approved      : data.isApproved,
  }

  let document = GetGridDocument(newData.location);  

  try
  {
    await updateDoc(doc(reportsRef, document), 
  {
    [`${id}.approved`]      : newData.approved,
    [`${id}.damageType`]    : newData.damageType,
    [`${id}.location`]      : newData.location,
    [`${id}.verifiedArray`] : newData.verifiedArray,
  }, { merge: true });
  }
  catch (error)
  {
    console.log(error);
  }

  dispatch(GetMarkersAfterReport());
  dispatch({type: 'UPLOADING', uploading : false});
}

export const UpdateReport = (data) =>async (dispatch) =>
{ 
  console.log(data);
  let id = data.report.id;
  let url = null;
  const document = GetGridDocument(data.report.location);
  const path = `/reports/${data.date}/`;

  try
  {
    /* REPORT LOADING */
    dispatch({type: 'UPLOADING', uploading : true});
    toastr.success("APORTE VIAL", 'REPORTANDO....');
   
    if(data.image)
    {
      const imageRef = ref(storage, path);            
      await uploadString(imageRef, data.image, 'data_url');
      url = await getDownloadURL(imageRef)

      
      let newReport = 
      {
        id          : data.reportsLength + 1,
        type        : data.reportType,
        image       : url,
        date        : data.date,
        user        : data.user.uid,
        phoneNumber : data.user.phoneNumber,
      }
     
      let newData = 
      {
        reports     :
        {
          ...data.report.reports,
          [`report${data.reportsLength + 1}`] : newReport,
        },
      }

      const reportsRef = collection(db, "markers");

      await updateDoc(doc(reportsRef, document), 
      {
        [`${id}.reports`] : newData.reports
      }, { merge: true });

      dispatch({type: 'SET_REPORT_SNAPSHOT', reportSnapshot : null});
      dispatch(GetMarkersAfterReport());

      dispatch({type: 'UPLOADING', uploading : false});
    }
    else
    {
      toastr.error("APORTE VIAL", 'FOTO DEL DAÑO ES NECESARIO');
    }    

    toastr.success("APORTE VIAL", 'GRACIAS POR ACTUALIZAR APORTE');
  }
  catch (error)
  {
    console.log(error);
    toastr.error("APORTE VIAL", 'HUBO PROBLEMAS AL SUBIR APORTE');
  }
 
}

export const AddView = (data) => async () =>
{
  const reportsRef = collection(db, "markers");

  let id = data.id;
  let document = GetGridDocument(data.location);  

  try
  {
    await updateDoc(doc(reportsRef, document), 
    {
      [`${id}.views`] : increment(1)
    }, { merge: true });
  }
  catch (error)
  {
    console.log(error);
  }
}

export const GetGridDocument = (location) => 
{
  let locationNE = [((Math.floor(location[0] * 10)/10) + 0.1).toFixed(1), ((Math.floor(location[1] * 10)/10) + 0.1).toFixed(1)];
  let folder = `@${locationNE}`;

  return folder;
} 

export const UploadAporte = (data) =>async (dispatch) =>
{ 
  let url = null;
  const document = GetGridDocument(data.location);
  const path = `/reports/${data.date}/`;
 
  try
  {
    /* REPORT LOADING */
    dispatch({type: 'UPLOADING', uploading : true});
    toastr.success("APORTE VIAL", 'REPORTANDO....');

    const imageRef = ref(storage, path);
        
    await uploadString(imageRef, data.image, 'data_url');

    url = await getDownloadURL(imageRef);

    let newData = 
    {
      id          : data.date,
      location    : data.location,
      reports     :
      {
        report1 :
        {
          id          : 1,
          type        : 'damage',
          image       : url,
          date        : data.date,
          user        : data.user.uid,            
          phoneNumber : data.user.phoneNumber,
        },
      },

      damageType    : data.type,

      verifiedArray : [],
      views         : 0,
      
      approved      : true,
    }

    const reportsRef = collection(db, "markers");

    await setDoc(doc(reportsRef, document), 
    {
      [`${data.date}`] : newData
    }, { merge: true });


    dispatch(GetMarkersAfterReport());
    dispatch({type: 'UPLOADING', uploading : false});


    UploadAporteNotificacion(data);
    toastr.success("APORTE VIAL", 'GRACIAS POR AÑADIR APORTE');
  }
  catch (error)
  {
    console.log(error);
    toastr.error("APORTE VIAL", 'HUBO PROBLEMAS AL SUBIR APORTE');
  } 
}