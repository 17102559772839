//Dependecies
import React, { useEffect } from 'react';
import { toastr } from 'react-redux-toastr';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from "react-router-dom";
//Databases
import dimensions from '../../databases/dimensions';
//Components
import Drawer01 from '../components/drawers/Drawer01';
import Drawer02 from '../components/drawers/Drawer02';
import Map02 from '../components/maps/Map02';
import Menu02 from '../components/menus/Menu02';
import Menu03 from '../components/menus/Menu03';
import Modal03 from '../components/modals/Modal03';
import Sidemenu02 from '../components/sidemenus/Sidemenu02';
//Actions
import { GetMarkersTest } from '../../store/actions/LoadingActions';

const ReportRouter = () =>
{
  const dispatch = useDispatch();
  let { id } = useParams();

  const loading = useSelector(state => state.app.loading);

  const user = useSelector(state => state.auth.user);

  const liveLocation = useSelector(state => state.map.liveLocation);
  const location = useSelector(state => state.map.location);
  const center = useSelector(state => state.map.center);
  const zoom = useSelector(state => state.map.zoom);
  const layers = useSelector(state => state.map.layers); 
  const group = useSelector(state => state.map.group); 
  const filter = useSelector(state => state.map.filter); 

  const markersDB = useSelector(state => state.markers.markersDB);
  const selected = useSelector(state => state.markers.selected);
  const last = useSelector(state => state.markers.last);

  const drawer01 = useSelector(state => state.drawers.drawer01);
  const drawer02 = useSelector(state => state.drawers.drawer02);
  
  /* DIMENSIONS */
  const height = window.innerHeight;
  const width = window.innerWidth;
  
  /* LOADING MARKERS */
  useEffect(() => 
  {
    dispatch(GetMarkersTest());
    dispatch({type: 'LOADING', loading : false});
  }, [dispatch]);

  /* CHECK ID */
  useEffect(() => 
  {
    if(markersDB && id)
    {
      try 
      {
        const markerID = Object.keys(markersDB).find(f1 => markersDB[f1].id === parseInt(id));
        
        if(markerID)
        {
          let info = markersDB[markerID];

          dispatch({type: 'SET_CENTER', center : info.location});
          
          setTimeout(() => 
          {
            dispatch({type: 'SET_MARKERS_SELECTED', selected : info});
            dispatch({type: 'SET_LAST', last : info});
          }, 200); 

          setTimeout(() => 
          {
            dispatch({type: 'SET_DRAWER_02', drawer02 : true});
          }, 400);

          if(zoom < 14 )
          {
            setTimeout(() => 
            {
              dispatch({type: 'SET_ZOOM', zoom : 14});
            }, 400);
          }
        }
        else
        {
          toastr.error("APORTE VIAL", 'PÁGINA NO ENCONTRADA');
        } 
      }
      catch(error)
      {
        console.log(error);
      }  
    }
    else
    {
      dispatch({type: 'SET_DRAWER_01', drawer01 : false});      
      dispatch({type: 'SET_DRAWER_02', drawer02 : false});

      dispatch({type: 'SET_REPORT_SNAPSHOT', reportSnapshot : null});
      dispatch({type: 'SET_MARKERS_SELECTED', selected : null});
    }
  }, [dispatch, markersDB, id]);
  
  if(loading) return(
    <Menu02
      height        = {Math.round(dimensions.menu * height / 100)}        
      visible       = {true}
    />
  );

  return(
    <React.Fragment>
      {markersDB && center && zoom && 
        <Map02
          liveLocation  = {liveLocation}
          location      = {location}
          height        = {window.innerHeight}
          center        = {center}
          zoom          = {zoom}
          filter        = {filter}
          paddingTop    = {0}
          paddingBottom = {Math.round(dimensions.menu * height / 100)}
          visible       = {true}
          markers       = {markersDB}
          layers        = {layers}
          group         = {group}
          last          = {last}
        />
      }

      {drawer01 &&
        <Drawer01
          liveLocation  = {liveLocation}
          location      = {location}
          user          = {user}
          visible       = {drawer01}
        />
      }

      <Modal03
        height        = {Math.round(dimensions.modal01 * height / 100)}
        width         = {Math.round(95 * width / 100)}
        visible       = {true}
        group         = {group}
      />

      {selected && drawer02 &&
        <Drawer02
          visible       = {drawer02}
          info          = {selected}
          user          = {user}
          liveLocation  = {liveLocation}
          location      = {location}
        />
      }

      <Sidemenu02
        height        = {Math.round(dimensions.menu * height / 100)}
        layers        = {layers}
        group         = {group}
        visible       = {true}
        location      = {location}
        liveLocation  = {liveLocation}
      />    
      
      <Menu03
        height        = {Math.round(dimensions.menu * height / 100)}
        filter        = {filter}
        visible       = {true}

        liveLocation  = {liveLocation}
      />
    </React.Fragment>
  );
}

export default ReportRouter;