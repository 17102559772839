import { collection, getFirestore, getDocs } from "firebase/firestore";
import { toastr } from 'react-redux-toastr';

export const GetMarkers = () => async (dispatch) => 
{
  dispatch({type: 'LOADING', loading : true});

  try 
  {
    let dataArray = [];

    const db = getFirestore();
    const markersRef = collection(db, 'markers');

    const markersDocs = await getDocs(markersRef);
 
    markersDocs.forEach((doc) => 
    {
      let markersArray = doc.data();
     
      for(let i = 0; i < Object.keys(markersArray).length; i++)
      {
        let info = markersArray[Object.keys(markersArray)[i]];

        if(info.approved)
        {
          dataArray.push({...info});
        }        
      };
    });

    dispatch({type: 'LOADING', loading : false});
    dispatch({type: 'SET_MARKERS_DB', markersDB : dataArray});    
  } 
  catch (error) 
  {
    console.log(error);
    toastr.error("APORTE VIAL", 'PROBLEMAS EN CARGAR INFORMACIÓN');
  }
}

export const GetMarkersTest = () => async (dispatch) => 
{
  dispatch({type: 'LOADING', loading : true});

  try 
  {
    let dataArray = [];

    const db = getFirestore();
    const markersRef = collection(db, 'markers');

    const markersDocs = await getDocs(markersRef);
 
    markersDocs.forEach((doc) => 
    {
      let markersArray = doc.data();
     
      for(let i = 0; i < Object.keys(markersArray).length; i++)
      {
        let info = markersArray[Object.keys(markersArray)[i]];

        if(info.approved)
        {
          dataArray.push({...info});
        }
      };
    });

    dispatch({type: 'LOADING', loading : false});
    dispatch({type: 'SET_MARKERS_DB', markersDB : dataArray});
  } 
  catch (error) 
  {
    console.log(error);
    toastr.error("APORTE VIAL", 'PROBLEMAS EN CARGAR INFORMACIÓN');
  }
}

export const GetMarkersMaster = () => async (dispatch) => 
{
  dispatch({type: 'LOADING', loading : true});

  try 
  {
    let dataArray = [];

    const db = getFirestore();
    const markersRef = collection(db, 'markers');

    const markersDocs = await getDocs(markersRef);
 
    markersDocs.forEach((doc) => 
    {
      let markersArray = doc.data();
     
      for(let i = 0; i < Object.keys(markersArray).length; i++)
      {
        let info = markersArray[Object.keys(markersArray)[i]];

        dataArray.push({...info});
      };
    });

    dispatch({type: 'LOADING', loading : false});
    dispatch({type: 'SET_MARKERS_DB', markersDB : dataArray});    
  } 
  catch (error) 
  {
    console.log(error);
    toastr.error("APORTE VIAL", 'PROBLEMAS EN CARGAR INFORMACIÓN');
  }
}

export const GetMarkersAfterReport = () => async (dispatch) => 
{
  dispatch({type: 'UPLOADING', uploading : true});

  try 
  {
    let dataArray = [];

    const db = getFirestore();
    const markersRef = collection(db, 'markers');

    const markersDocs = await getDocs(markersRef);
 
    markersDocs.forEach((doc) => 
    {
      let markersArray = doc.data();
     
      for(let i = 0; i < Object.keys(markersArray).length; i++)
      {
        let info = markersArray[Object.keys(markersArray)[i]];

        dataArray.push({...info});
      };
    });

    dispatch({type: 'UPLOADING', uploading : false});
    dispatch({type: 'SET_MARKERS_DB', markersDB : dataArray});    
  } 
  catch (error) 
  {
    console.log(error);
    toastr.error("APORTE VIAL", 'PROBLEMAS EN CARGAR INFORMACIÓN');
  }
}