//Dependencies
import React, { useState } from 'react';
import { Modal, Badge, Select } from 'antd';
import { useDispatch } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { useNavigate } from 'react-router-dom';
import ProgressiveImage from 'react-progressive-graceful-image';
import { CloseOutlined , EyeOutlined } from '@ant-design/icons'; 
//Databases
import list01 from '../../../databases/list01';
//Actions
//import { CloseReport } from '../../../store/actions/NotificationsActions';

const { Option } = Select;

/* MODAL DE REPORTE - SOLO VIEW */
const Modal02 = (props) =>
{
  const { info, height,  width, visible } = props; 
  const dispatch = useDispatch();
  const navigate = useNavigate(); 

  const actualDate = new Date().getTime();
  const reportsLength = Object.keys(info.reports).length;  

  const onLinkClick = () =>
  {
    navigator.clipboard.writeText(`${window.location.origin}/${info.id}`);

    toastr.success("APORTE VIAL", 'ENLACE COPIADO');
  }

  const closeModal = () => 
  {
    dispatch({type: 'SET_MARKERS_SELECTED', selected : null});
    dispatch({type: 'SET_MODAL_02', modal02 : false});

    //CloseReport(info.id);

    navigate('/');
  }; 

  const getReportsArray = () =>
  {
    let reportsArray = [];

    for (let i = 0; i < reportsLength; i++)
    {
      let info01 = `report${i + 1}`
      reportsArray.push(info01);
    }

    return reportsArray.reverse();
  }

  const getDate = (date) =>
  {
    let dateFormat = new Date(date);
    return (dateFormat.getFullYear() + ' - ' + (dateFormat.getMonth() + 1) + ' - '+ dateFormat.getDate());
  };

  const getNumberOfDays = (start, end) =>
  {
    const date1 = new Date(start);
    const date2 = new Date(end);

    // One day in milliseconds
    const oneDay = 1000 * 60 * 60 * 24;

    // Calculating the time difference between two dates
    const diffInTime = date2.getTime() - date1.getTime();

    // Calculating the no. of days between two dates
    const diffInDays = Math.round(diffInTime / oneDay);

    return diffInDays;
  };

  const [reportSelected, setReportSelected] = useState(getReportsArray(info.reports)[0]);

  if(!info) return null;
  
  return(
    <Modal
      title     = {null}
      closable  = {false}
      visible   = {visible}
      onCancel  = {() => closeModal()}
      width       = {448}
      footer    = {null}
      style     = {{ top: 20 }}
      bodyStyle = {{ borderRadius: '0px 0px 15px 15px !important', maxWidth : `${448}px`}}
      className = 'rounded-lg'
    >
      <div className = "max-w-md mx-auto">
        <div>
          <p className = "flex justify-center text-sm md:text-xl font-semibold font-mono my-0 mb-2">
            {list01.find(f1 => (f1.value === info.damageType)).text.toUpperCase()}

            {info.views > 0 &&
              <React.Fragment>
                &nbsp;
                -
                &nbsp;

                <Badge count = {info.views} overflowCount = {100} size = "large">
                  <EyeOutlined 
                    className ='text-xl md:text-2xl lg:text-2xl'
                  />
                </Badge>
              </React.Fragment> 
            }           
          </p>

          <div className = "flex justify-center w-full py-2">
            <Select 
              defaultValue = {getReportsArray(info.reports)[0]} 
              style = {{ width: 350 }}
              onChange = {(e) => setReportSelected(e)}
              className = "mt-2"
            >
              {getReportsArray(info.reports).map((m1, i) => 
                {
                  let info01 = info.reports[m1];
                  let damageType;
                  let damageColor;

                  switch(info01.type) 
                  {
                    case 'damage':
                      damageType = 'DAÑO'
                      damageColor = 'text-red-600'
                      break;
                    case 'update':
                      damageType = 'ACTUALIZACIÓN'
                      damageColor = 'text-yellow-600'
                      break;
                    case 'repair':
                      damageType = 'REPARACIÓN'
                      damageColor = 'text-green-600'
                      break;
                    default:
                      // code block
                  }

                  return(
                    <Option key = {i} value = {m1}>
                      <p className = {`flex m-0 p-0 font-semibold justify-center text-base ${damageColor}`}>
                        {`${damageType} / ${getDate(info01.date)}`}
                      </p>                      
                    </Option>
                  )
                })
              }
            </Select>
          </div>

          {info.reports[reportSelected].type === 'damage' &&
            <p className = "mb-2 text-center text-sm md:text-base text-red-500 font-semibold uppercase">
              {`${getNumberOfDays(info.reports[reportSelected].date, actualDate)} ${getNumberOfDays(info.reports[reportSelected].date, actualDate) === 1 ? 'día' : 'días'} desde que se hizo este aporte`}
            </p>
          }

          {info.reports[reportSelected].type === 'repair' &&
            <p className = "mb-2 text-center text-sm md:text-base text-red-500 font-semibold uppercase">
              {`${getNumberOfDays(info.reports[`report${info.reports[reportSelected].id - 1}`].date, info.reports[reportSelected].date)} ${getNumberOfDays(info.reports[`report${info.reports[reportSelected].id - 1}`].date, info.reports[reportSelected].date) === 1 ? 'día' : 'días'} para arreglar el daño`}
            </p>
          }
          
          <ProgressiveImage 
            src = {info.reports[reportSelected].image}
            placeholder = {'https://firebasestorage.googleapis.com/v0/b/bettercityio.appspot.com/o/extra%2Fblurimage.jpg?alt=media&token=1f2ea1d6-8609-4225-abf0-77a31f10b732'}
          >
            {(src) => {
              return (
                <img 
                  src = {src} 
                  style = {{ width: height * 0.90, height: height * 0.90,  maxHeight : `${448 * 0.9}px`, maxWidth : `${Math.min(448, width)}px`, margin: 'auto'}}
                  alt = 'report'
                  className ='shadow-xl'
                />
              );
            }}           
          </ProgressiveImage>

          <div className = "pt-2 text-base font-semibold flex justify-evenly border-solid border-grey-100">
            <div
              className = "text-white text-center inline-flex items-center justify-center text-xl md:text-2xl lg:text-2xl w-12 h-12 md:w-14  md:h-14  lg:w-14 lg:h-14 mb-2 shadow-lg rounded-full bg-gray-800 hover:bg-sky-600 cursor-pointer"
              onClick   = {() => onLinkClick()}
            >
              <i className = "fas fa-link"></i>
            </div>

            <a
              className = "text-white text-center inline-flex items-center justify-center text-xl md:text-2xl lg:text-3xl w-12 h-12 md:w-14  md:h-14  lg:w-14 lg:h-14 mb-2 shadow-lg rounded-full bg-gray-800 hover:bg-sky-600 cursor-pointer"
              target    = '_blank'
              rel       = "noopener noreferrer"
              href      = {`https://www.waze.com/ul?ll=${info.location[0]},${info.location[1]}&navigate=yes`}
            >
              <i className = "text-white fab fa-waze" ></i>
            </a>

            <a 
              className = "text-white text-center inline-flex items-center justify-center text-xl md:text-2xl lg:text-2xl w-12 h-12 md:w-14  md:h-14  lg:w-14 lg:h-14 mb-2 shadow-lg rounded-full bg-gray-800 hover:bg-sky-600 cursor-pointer"
              target    = '_blank'
              rel       = "noopener noreferrer"
              href      = {`https://www.google.com/maps/dir/?api=1&destination=${info.location[0]}%2C${info.location[1]}`}
            >
              <i className = "text-white fas fa-map-marked"></i>
            </a>

            <div
              className = "text-white text-center inline-flex items-center justify-center text-xl md:text-2xl lg:text-2xl w-12 h-12 md:w-14  md:h-14  lg:w-14 lg:h-14 mb-2 shadow-lg rounded-full bg-red-600 hover:bg-sky-600 cursor-pointer"
              onClick   = {() => closeModal()}
            >
              <CloseOutlined  
                className ='text-xl md:text-2xl lg:text-2xl'
              />
            </div>           
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default Modal02;