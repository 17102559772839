const initState =
{
  markersDB         : null,
  selected          : null,
  selectedBranches  : null,
  last              : null,
}

const markersReducer = (state = initState, action) =>
{
  switch (action.type)
  {    
    case 'SET_MARKERS_DB':
      return {
        ...state,
        markersDB: action.markersDB
      };

    case 'SET_LAST':
      return {
        ...state,
        last: action.last
      };

    case 'SET_MARKERS_SELECTED':
      return {
        ...state,
        selected: action.selected
      };

    case 'SET_MARKERS_SELECTED_BRANCHES':
      return {
        ...state,
        selectedBranches: action.selectedBranches
      };

    default:
      return state; 
  }
}

export default markersReducer;