const initState =
{
  modal01       : false,
  modal02       : false,
  modal03       : false,
  modal04       : false,
}

const modalsReducer = (state = initState, action) =>
{
  switch (action.type)
  {    
    case 'SET_MODAL_01':
      return {
        ...state,
        modal01: action.modal01
      };

    case 'SET_MODAL_02':
      return {
        ...state,
        modal02: action.modal02
      };

    case 'SET_MODAL_03':
      return {
        ...state,
        modal03: action.modal03
      };

    case 'SET_MODAL_04':
      return {
        ...state,
        modal04: action.modal04
      };

    default:
      return state; 
  }
}

export default modalsReducer;