const initState =
{
  layers          : true,
  group           : false,
  filter          : null,
  location        : null,
  liveLocation    : null,
  center          : ['-0.167722', '-78.480243'],
  zoom            : 14,
}

const mapReducer = (state = initState, action) =>
{
  switch (action.type)
  {
    case 'SET_LAYERS':
      return {
        ...state,
        layers: action.layers
      };

    case 'SET_MAP_MARKER_GROUP':
      return {
        ...state,
        group: action.group
      };

    case 'SET_FILTER':
      return {
        ...state,
        filter: action.filter
      };

    case 'SET_LOCATION':
      return {
        ...state,
        location: action.location
      };

    case 'SET_LIVE_LOCATION':
      return {
        ...state,
        liveLocation: action.liveLocation
      };

    case 'SET_CENTER':
      return {
        ...state,
        center: action.center
      };

    case 'SET_ZOOM':
      return {
        ...state,
        zoom: action.zoom
      };       

    default:
      return state; 
  }
}

export default mapReducer;