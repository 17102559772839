import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import firebase from './assets/config/fbConfig';
import RootReducer from './assets/store/reducers/RootReducer';  
import { createStore, applyMiddleware } from "redux";
import reduxThunk from "redux-thunk";
import { Provider } from 'react-redux';
import { ReactReduxFirebaseProvider } from 'react-redux-firebase';
import ReduxToastr from 'react-redux-toastr';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import LogRocket from 'logrocket';
LogRocket.init('ltvif9/aportevial');

const store = createStore(RootReducer, {}, applyMiddleware(reduxThunk));

const rrfConfig = 
{

}

const rrfProps = 
{
  firebase,
  config    : rrfConfig,
  dispatch  : store.dispatch
}

ReactDOM.render(
  <Provider store = {store} >
    <ReactReduxFirebaseProvider {...rrfProps}>
      <ReduxToastr
        timeOut       = {3000}
        position      = 'bottom-right'
        transitionIn  = 'fadeIn'
        transitionOut = 'fadeOut'
      />
      <App />
    </ReactReduxFirebaseProvider>
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();