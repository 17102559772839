//Dependencies
import React from 'react';
import { useDispatch } from 'react-redux';
//Actions
import { GetLocation } from '../../../store/actions/MapActions';

/* UBICACIÓN DE EN MAPA - QUITO */
const Popover01 = () =>
{
  const dispatch = useDispatch();

  const onLocationSelected = (location, zoom) =>
  {
    dispatch({type: 'SET_CENTER',center : location});
    dispatch({type: 'SET_ZOOM', zoom : zoom});
  }

  return(
    <div>
      <h1 className = "flex justify-center mb-1 text-3xl text-blue-600 font-bold select-none">
        QUITO
      </h1>

      <div 
        className = "h-[40px] w-[300px] flex justify-center bg-transparent hover:bg-sky-500 text-gray-800 font-semibold  border-2 border-gray-800 rounded my-1 cursor-pointer select-none hover:text-white"
        onClick   = {() => dispatch(GetLocation())}
      >
        <h1 
          className = 'flex items-center m-0'
        >
          UBICACIÓN ACTUAL
        </h1>
      </div>

      <div 
        className = "h-[40px] w-[300px] flex justify-center bg-transparent hover:bg-sky-500 text-gray-800 font-semibold  border-2 border-gray-800 rounded my-1 cursor-pointer select-none hover:text-white"
        onClick   = {() => onLocationSelected(['-0.031929', '-78.443069'], 14)}
      >
        <h1 
          className = 'flex items-center m-0'
        >
          MITAD DEL MUNDO
        </h1>
      </div>

      <div 
        className = "h-[40px] w-[300px] flex justify-center bg-transparent hover:bg-sky-500 text-gray-800 font-semibold  border-2 border-gray-800 rounded my-1 cursor-pointer select-none hover:text-white"
        onClick   = {() => onLocationSelected(['-0.105743', '-78.417320'], 14)}
      >
        <h1 
          className = 'flex items-center m-0'
        >
          CALDERÓN
        </h1>
      </div>

      <div 
        className = "h-[40px] w-[300px] flex justify-center bg-transparent hover:bg-sky-500 text-gray-800 font-semibold  border-2 border-gray-800 rounded my-1 cursor-pointer select-none hover:text-white"
        onClick   = {() => onLocationSelected(['-0.121622', '-78.479719'], 14)}
      >
        <h1 
          className = 'flex items-center m-0'
        >
          NORTE
        </h1>
      </div>

      <div 
        className = "h-[40px] w-[300px] flex justify-center bg-transparent hover:bg-sky-500 text-gray-800 font-semibold  border-2 border-gray-800 rounded my-1 cursor-pointer select-none hover:text-white"
        onClick   = {() => onLocationSelected(['-0.195350', '-78.491478'], 14)}
      >
        <h1 
          className = 'flex items-center m-0'
        >
          CENTRO
        </h1>
      </div>

      <div 
        className = "h-[40px] w-[300px] flex justify-center bg-transparent hover:bg-sky-500 text-gray-800 font-semibold  border-2 border-gray-800 rounded my-1 cursor-pointer select-none hover:text-white"
        onClick   = {() => onLocationSelected(['-0.280150', '-78.534050'], 14)}
      >
        <h1 
          className = 'flex items-center m-0'
        >
          SUR
        </h1>
      </div>
      
      <div 
        className = "h-[40px] w-[300px] flex justify-center bg-transparent hover:bg-sky-500 text-gray-800 font-semibold  border-2 border-gray-800 rounded my-1 cursor-pointer select-none hover:text-white"
        onClick   = {() => onLocationSelected(['-0.203247', '-78.434830'], 14)}
      >
        <h1 
          className = 'flex items-center m-0'
        >
          CUMBAYA
        </h1>
      </div>

      <div 
        className = "h-[40px] w-[300px] flex justify-center bg-transparent hover:bg-sky-500 text-gray-800 font-semibold  border-2 border-gray-800 rounded my-1 cursor-pointer select-none hover:text-white"
        onClick   = {() => onLocationSelected(['-0.215778', '-78.401871'], 14)}
      >
        <h1 
          className = 'flex items-center m-0'
        >
          TUMBACO
        </h1>
      </div>

      <div 
        className = "h-[40px] w-[300px] flex justify-center bg-transparent hover:bg-sky-500 text-gray-800 font-semibold  border-2 border-gray-800 rounded my-1 cursor-pointer select-none hover:text-white"
        onClick   = {() => onLocationSelected(['-0.328730', '-78.441353'], 14)}
      >
        <h1 
          className = 'flex items-center m-0'
        >
          VALLE DE LOS CHILLOS
        </h1>
      </div>
    </div>
  );
}

export default Popover01;