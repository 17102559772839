//Dependencies
import React, { useState } from 'react';
import { Divider, Select, Switch } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ProgressiveImage from 'react-progressive-graceful-image';
//Components
import CameraCapture from '../camera/CameraCapture';
//Main Components
import Drawer from '../../../components/Drawer';
//Database
import list01 from '../../../databases/list01';
//Scripts
import { PointInCircle } from '../../../scripts/Helpers';
//Actions
import { UpdateDamage, UpdateReport } from '../../../store/actions/ReportActions';

const { Option } = Select;

/* DRAWER DE REPORTE - UPDATE */
const Drawer02 = (props) =>
{
  const { info, liveLocation, location, visible, user } = props;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  const reportSnapshot = useSelector(state => state.reports.reportSnapshot);
  const uploading = useSelector(state => state.app.uploading); 

  const actualDate = new Date().getTime();
  const reportsLength = Object.keys(info.reports).length;
  const lastReportID = Object.keys(info.reports).find(f1 => info.reports[f1].id === reportsLength);
  const lastReport = info.reports[lastReportID];  

  const [damageType, setDamageType] = useState(info.damageType);
  const [reportType, setReportType] = useState(lastReport.type === 'repair' ? 'damage' : null);
  const [isVerified, setIsVerified] = useState(info.verifiedArray.includes(user.uid));
  const [isApproved, setIsApproved] = useState(info.approved);
  const [isLocationChange, setIsLocationChange] = useState(false);

  const closeDrawer = () =>
  {
    const scroller = document.querySelector("#scroller");   
    scroller.scrollTo(0,0);

    dispatch({type: 'SET_REPORT_SNAPSHOT', reportSnapshot : null});
    dispatch({type: 'SET_MARKERS_SELECTED', selected : null});   
    dispatch({type: 'SET_DRAWER_02', drawer02 : false});

    navigate('/app');
  };

  const getDate = (date) =>
  {
    let dateFormat = new Date(date);
    return (dateFormat.getFullYear() + ' - ' + (dateFormat.getMonth()+1) + ' - '+ dateFormat.getDate());
  };

  const getNumberOfDays = (start, end) =>
  {
    const date1 = new Date(start);
    const date2 = new Date(end);

    // One day in milliseconds
    const oneDay = 1000 * 60 * 60 * 24;

    // Calculating the time difference between two dates
    const diffInTime = date2.getTime() - date1.getTime();

    // Calculating the no. of days between two dates
    const diffInDays = Math.round(diffInTime / oneDay);

    return diffInDays;
  };

  const sendReport = () =>
  {
    let date = new Date().getTime();

    let data =
    {
      date            : date,
      image           : reportSnapshot,
      user            : user,      
      report          : info,
      reportsLength   : reportsLength,
      reportType      : reportType,
    }

    dispatch(UpdateReport(data));
    setDamageType(info.reportType);
    setReportType(lastReport.type === 'repair' ? 'damage' : null);
  }

  const updateReport = () =>
  {
    let data =
    {
      location          : location,
      damageType        : damageType,
      report            : info,
      reportsLength     : reportsLength,
      isApproved        : isApproved,
      isVerified        : isVerified,
      alreadyVerified   : info.verifiedArray.includes(user.uid),
      isLocationChange  : isLocationChange,
      user              : user,
    }

    dispatch(UpdateDamage(data));
  }

  const getReportsArray = () =>
  {
    let reportsArray = [];

    for (let i = 0; i < reportsLength; i++)
    {
      let info01 = `report${i + 1}`
      reportsArray.push(info01);
    }

    return reportsArray.reverse();
  }

  if(!info) return null;

  return(
    <Drawer
      height    = {80}
      visible   = {visible}
      onClose   = {() => closeDrawer()}
    >
      <div className = "w-full bg-white select-none">
        <div className = "flex bg-white"  >
          <div className = "flex items-center flex-col text-center px-6 md:px-12 md:w-full sm:w-full w-full" >
            <div className = "w-full my-1">
              <h2 className = "text-xl font-bold text-gray-800 uppercase md:text-2xl my-0">
                DATOS DEL DAÑO
              </h2>
            </div>            

            <Select 
              defaultValue = {info.damageType} 
              style = {{ width: 350 }}
              onChange = {(e) => setDamageType(e)}              
            >
              {list01 && Object.keys(list01).map((m1, i) => 
              {
                let info = list01[m1];

                return(
                  <Option key = {i} value = {info.value}>{info.text.toUpperCase()}</Option>
                )
              })
            }     
            </Select>

            <div className = "w-full my-1">
              <h1 className = "flex justify-center text-xl font-semibold font-mono my-0">
                {`NÚMERO DE VISITAS : ${info.views}`}
              </h1>

              <h1 className = "flex justify-center text-xl font-semibold font-mono my-0">
                {`NÚMERO DE VERIFICACIONES : ${info.verifiedArray.length}`}
              </h1>
            </div>

            <div className = "w-full my-1">
              <h1 className = "flex justify-center text-xl font-semibold font-mono my-0">
                APROBADO
              </h1>
            </div>
            
            <Switch
              defaultChecked  = {isApproved}
              onChange        = {(e) => setIsApproved(e)}
            />

            <div className = "w-full my-1">
              <h1 className = "flex justify-center text-xl font-semibold font-mono my-0">
                SE CAMBIÓ UBICACIÓN?
              </h1>
            </div>
            
            <Switch
              defaultChecked  = {isLocationChange}
              onChange        = {(e) => setIsLocationChange(e)}
            />
           
            <div className = "w-full my-1">
              <h1 className = "flex justify-center text-xl font-semibold font-mono my-0">
                VERIFICAR DAÑO
              </h1>
            </div>            

            {info.verifiedArray.includes(user.uid) &&
              <h1 className = "flex justify-center text-base font-semibold font-mono my-0 text-green-500">
                DAÑO YA ESTÁ VERIFICADO POR ESTE USUARIO
              </h1>
            }

            {!info.verifiedArray.includes(user.uid) &&
              <React.Fragment>
                {!liveLocation &&
                  <h1 className = "flex justify-center text-base font-semibold font-mono my-0 text-red-500">
                    SE NECESITA TU UBICACIÓN PARA PODER VERIFICAR
                  </h1>
                }
                
                {liveLocation && PointInCircle(info.location ,liveLocation, 30) &&
                  <h1 className = "flex justify-center text-base font-semibold font-mono my-0 text-green-500">
                    ESTÁS DENTRO DEL RANGO DE 30m PARA PODER VERIFICAR
                  </h1>
                }

                {liveLocation && !PointInCircle(info.location ,liveLocation, 30) &&
                  <h1 className = "flex justify-center text-base font-semibold font-mono my-0 text-red-500">
                    NO ESTÁS DENTRO DEL RANGO DE 30m PARA PODER VERIFICAR
                  </h1>
                }
                
                {liveLocation && PointInCircle(info.location ,liveLocation, 30) &&
                  <Switch 
                    defaultChecked  = {isVerified}
                    disabled        = {info.verifiedArray.includes(user.uid)}
                    onChange        = {(e) => setIsVerified(e)}
                  />
                }
              </React.Fragment>
            }           

            <div className = 'flex items-center justify-evenly'>
              <div 
                className = 'flex items-center justify-evenly'
              >
                <button 
                  className = 'w-full mt-3 bg-sky-600 hover:bg-sky-900 rounded px-6 py-2 text-lg text-white tracking-wide font-semibold font-mono'
                  onClick = {() => updateReport()}
                >
                  ACTUALIZAR DAÑO
                </button>
              </div>
            </div>

            <Divider/>

            <div className = "w-full">
              {(lastReport.type === 'repair' || lastReport.type === 'update') &&                
                <h2 className = "text-xl font-bold text-gray-800 uppercase md:text-2xl mt-1 mb-1">
                  APORTE DE DAÑO
                </h2>
              }

              {!reportType &&
                <h1 className = "flex justify-center text-base font-semibold font-mono my-0 text-red-500">
                  DEBES SELECCIONAR TIPO DE APORTE
                </h1>
              }

              {(lastReport.type === 'damage' || lastReport.type === 'update') &&
                <Select 
                  style = {{ width: 350 }}
                  placeholder = 'TIPO DE APORTE'
                  onChange = {(e) => setReportType(e)}
                >
                  <Option value = 'update'>APORTE DE ACTUALIZACIÓN</Option>                  
                  <Option value = 'repair'>APORTE DE REPARACIÓN</Option>
                </Select>
              }              
            </div>

            {reportSnapshot &&
              <div className = "py-2">
                <ProgressiveImage 
                  src = {reportSnapshot} 
                  placeholder = {'https://firebasestorage.googleapis.com/v0/b/bettercityio.appspot.com/o/extra%2Fblurimage.jpg?alt=media&token=1f2ea1d6-8609-4225-abf0-77a31f10b732'}
                >
                  {(src) => {
                    return (
                      <img 
                        src = {src} 
                        style = {{ width: window.innerWidth * 0.9, height: window.innerWidth * 0.9, maxWidth: 400, maxHeight: 400, margin: 'auto'}}
                        alt = 'report'
                        className ='shadow-xl'
                      />
                    );
                  }}
                </ProgressiveImage>

                <div className = 'flex items-center justify-evenly w-full my-5 mx-0 max-w-[400px]'>
                  {/*
                  <div 
                    className = "m-0 text-white text-center inline-flex items-center justify-center text-base w-10 h-10 md:text-xl md:w-12  md:h-12 lg:text-xl lg:w-12 lg:h-12 shadow-lg rounded-full bg-gray-800 hover:bg-sky-600 cursor-pointer"
                    onClick = {() => dispatch({type: 'SET_REPORT_SNAPSHOT', reportSnapshot : null})}
                  >
                    <i className = "fas fa-camera text-white m-0"></i>
                  </div>
                  */}

                  <div 
                    className = "m-0 text-white text-center inline-flex items-center justify-center text-base w-10 h-10 md:text-xl md:w-12  md:h-12 lg:text-xl lg:w-12 lg:h-12 shadow-lg rounded-full bg-red-600 hover:bg-red-800 cursor-pointer"
                    onClick = {() => dispatch({type: 'SET_REPORT_SNAPSHOT', reportSnapshot : null})}
                  >
                    <i className = "fas fa-trash text-white m-0"></i>
                  </div>
                </div>
              </div>
            }

            {!reportSnapshot &&
              <div
                className = "py-2"
              >
                <CameraCapture/>
              </div>
            }

            <div className = 'flex items-center justify-evenly'>
              <div 
                className = 'flex items-center justify-evenly'
              >
                <button 
                  className = {(reportSnapshot && reportType) ? "w-full mt-1 bg-sky-600 hover:bg-sky-900 rounded px-6 py-2 text-lg text-white tracking-wide font-semibold font-mono" : "w-full mt-1 bg-gray-600 rounded px-6 py-2 text-lg text-gray-200 tracking-wide font-semibold font-mono"} 
                  disabled = {(!reportSnapshot || !reportType) ? true : false}
                  onClick = {() => sendReport()}
                >
                  ENVIAR APORTE
                </button>
              </div>
            </div>

            {uploading &&
              <div className = "fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-gray-700 opacity-90 flex flex-col items-center justify-center">
                <div className = "loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4"></div>
                <h2 className = "text-center text-white text-2xl font-semibold font-mono">
                  SUBIENDO APORTE...
                </h2>
                <p className = "lg:w-1/3 text-center text-white font-mono text-xl">
                  Se está subiendo el aporte a nuestra base de datos.
                </p>
                <p className = "lg:w-1/3 text-center text-white font-mono text-xl">
                  Gracias por tu aporte.
                </p>
              </div>
            }

            <Divider/>
            <div className = "lg:w-full my-1">
              <h2 className = "text-xl font-bold text-gray-800 uppercase md:text-2xl my-0">
                APORTES ANTERIORES
              </h2>
            </div>
            <Divider/>

            {/* AQUÍ SE PONDRAM TODOS LOS REGISTROS NO reverse()*/}
            {getReportsArray(info.reports).map((m1, i) => 
              {
                let info01 = info.reports[m1];                
              
                return(
                  <React.Fragment
                    key = {i}
                  >
                    {info01.type === 'damage' &&
                      <h2 className = "text-xl font-bold text-gray-800 uppercase md:text-2xl my-1">
                        DAÑO
                      </h2>
                    }

                    {info01.type === 'update' &&
                      <h2 className = "text-xl font-bold text-gray-800 uppercase md:text-2xl my-1">
                        ACTUALIZACIÓN
                      </h2>
                    }

                    {info01.type === 'repair' &&
                      <h2 className = "text-xl font-bold text-gray-800 uppercase md:text-2xl my-1">
                        REPARACIÓN
                      </h2>
                    }

                    <p className = "my-0 text-center text-base md:text-xl text-gray-900">
                      {getDate(info01.date)}
                    </p>

                    {info01.id === 1 &&
                      <p className = "my-0 text-center text-base md:text-xl text-gray-900">
                        {`${getNumberOfDays(info01.date, actualDate)} ${getNumberOfDays(info01.date, actualDate) === 1 ? 'día' : 'días'} desde que se hizo este aporte`}
                      </p>
                    }

                    <div className = "py-2 text-base text-gray-600 ">
                      <ProgressiveImage 
                        src = {info01.image} 
                        placeholder = {'https://firebasestorage.googleapis.com/v0/b/bettercityio.appspot.com/o/extra%2Fblurimage.jpg?alt=media&token=1f2ea1d6-8609-4225-abf0-77a31f10b732'}
                      >
                        {(src) => {
                          return (
                            <img 
                              src = {src} 
                              style = {{ width: window.innerWidth * 0.9, height: window.innerWidth * 0.9, maxWidth: 400, maxHeight: 400, margin: 'auto'}}
                              alt = 'report'
                              className ='shadow-xl'
                            />
                          );
                        }}
                      </ProgressiveImage>
                    </div>

                    <Divider/>
                  </React.Fragment>
                )
              })
            }

            <button
              className = 'bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 border border-red-700 rounded lg:w-1/4 w-2/3 text-lg tracking-widest md:mb-5 mt-5'
              onClick={() => 
                {
                  closeDrawer();
                }}
            >
              CERRAR
            </button>      
          </div>
        </div>
      </div>
    </Drawer>
  );
}

export default Drawer02;