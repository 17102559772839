import { getAuth, onAuthStateChanged, RecaptchaVerifier, signInWithPhoneNumber, signOut } from "firebase/auth";
//Database
import bizlist01 from '../../databases/bizList01';

export const VerifyingCurrentUser = () => async (dispatch) =>
{
  const auth = getAuth();
  try
  {  
    dispatch({type: 'SET_AUTH_CHECKING', checking : true});

    onAuthStateChanged(auth, user => 
      {
        if(user)
        {
          dispatch({type: 'SET_AUTH_CONNECTED', connected : true});
          dispatch({type: 'SET_AUTH_USER', user : user});
          dispatch({type: 'SET_AUTH_CHECKING', checking : false});

          dispatch(CheckVerifiedUser(user.phoneNumber));
        }
        else
        {
          dispatch({type: 'SET_AUTH_CONNECTED', connected : false});
          dispatch({type: 'SET_AUTH_USER', user : null});
          dispatch({type: 'SET_AUTH_CHECKING', checking : false});
        }
      });    
  }
  catch (error)
  {
    console.log(error);
  }
}

//https://firebase.google.com/docs/auth/web/phone-auth?hl=el#web-version-9_1
export const GetVerificationId = (phone) => async (dispatch) => 
{
  const auth = getAuth();

  var appVerifier = new RecaptchaVerifier('recaptcha-container', {}, auth);
  signInWithPhoneNumber(auth, `+593${phone}`, appVerifier)
    .then((confirmationResult) => 
    {
      dispatch({type: 'SET_CONFIRMATION_RESULT', confirmationResult : confirmationResult});
    }).catch((error) => 
    {
      // Error; SMS not sent
      // ...
    });
}

export const SignIn = ( confirmationResult, verificationCode ) => async (dispatch) => 
{  
  try 
  {
    confirmationResult.confirm(verificationCode).then((result) => {
      const user = result.user;
     
      dispatch({type: 'SET_AUTH_USER', user : user});
    }).catch((error) => 
    {
      console.log(error);
      console.log('no se pudo conectar');
    });
  } 
  catch (error) 
  {

  }
}

export const SignOut = () => async (dispatch) => 
{  
  try 
  {
    const auth = getAuth();
    signOut(auth).then(() => 
    {
      dispatch({type: 'SET_AUTH_CONNECTED', connected : false});
      dispatch({type: 'SET_AUTH_USER', user : null});
      dispatch({type: 'SET_AUTH_CHECKING', checking : false});

      dispatch({type: 'SET_CONFIRMATION_RESULT', confirmationResult : null});
    }).catch((error) => 
    {
      // An error happened.
    });
  } 
  catch (error) 
  {

  }
}

export const CheckVerifiedUser = (phoneNumber) => async(dispatch) =>
{
  const exist = Object.keys(bizlist01).filter((f1) => bizlist01[f1].phonenumber === phoneNumber);

  if(exist.length > 0)
  {
    dispatch({type: 'SET_REPORT_VERIFIED', verified : true});
  }
}