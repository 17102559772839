//Dependencies
import React from 'react';
import { Affix } from 'antd';
import { InfoFilledIcon, LayerFilledIcon } from '../../../icons/icons';
import { GroupOutlined, LineChartOutlined , UngroupOutlined, ZoomInOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';

/* SIDEMENU */
const Sidemenu01 = (props) =>
{
  const { info, height, layers, visible, group } = props; 
  const dispatch = useDispatch();

  if(!visible) return null;

  const clickInfo = () =>
  {
    dispatch({type: 'SET_MODAL_01', modal01 : true});
  };

  const clickZoom = () =>
  {
    dispatch({type: 'SET_CENTER', center : info.location});

    setTimeout(() => 
    {
      dispatch({type: 'SET_ZOOM', zoom : 18});
    }, 400);
  };
  
  return(
    <Affix 
      style = {{ position: "fixed", bottom: height, right: 10, zIndex : 1000}}
    >
      <div>
      {info && info.location &&
          <div 
            className = "flex items-center justify-center text-white p-3 text-center mb-5 md:w-16 md:h-16 w-12 h-12 shadow-lg rounded-full cursor-pointer bg-gray-800 hover:bg-sky-600"
            onClick   = {() => clickZoom()}
          >
            <ZoomInOutlined  className = ' text-xl lg:text-3xl flex cursor-pointer '/>
          </div>
        }

        {!info &&
          <div 
            className = "flex items-center justify-center text-black p-3 text-center mb-5 md:w-16 md:h-16 w-12 h-12 shadow-lg rounded-full cursor-pointer bg-gray-300 cursor-default"
          >
            <ZoomInOutlined  className = ' text-xl lg:text-3xl flex cursor-default '/>
          </div>
        }
        
        <div 
          className = "flex items-center justify-center text-white p-3 text-center mb-5 md:w-16 md:h-16 w-12 h-12 shadow-lg rounded-full cursor-pointer bg-gray-800 hover:bg-sky-600"
          onClick   = {() => dispatch({type: 'SET_LAYERS', layers : !layers})}
        >
          <LayerFilledIcon className = ' flex text-xl lg:text-2xl flex cursor-pointer '/>
        </div>

        {group &&
          <div 
            className = "flex items-center justify-center text-white p-3 text-center mb-5 md:w-16 md:h-16 w-12 h-12 shadow-lg rounded-full cursor-pointer bg-gray-800 hover:bg-sky-600"
            onClick = {() => dispatch({type: 'SET_MAP_MARKER_GROUP', group : !group})}
          >
            <UngroupOutlined className = ' flex text-xl lg:text-2xl flex cursor-pointer '/>
          </div>
        }

        {!group &&
          <div 
            className = "flex items-center justify-center text-white p-3 text-center mb-5 md:w-16 md:h-16 w-12 h-12 shadow-lg rounded-full cursor-pointer bg-gray-800 hover:bg-sky-600"
            onClick = {() => dispatch({type: 'SET_MAP_MARKER_GROUP', group : !group})}
          >
            <GroupOutlined className = ' flex text-xl lg:text-2xl flex cursor-pointer '/>
          </div>
        }

        <div 
          className = "flex items-center justify-center text-white p-3 text-center mb-5 md:w-16 md:h-16 w-12 h-12 shadow-lg rounded-full cursor-pointer bg-gray-800 hover:bg-sky-600"
          onClick   = {() => dispatch({type: 'SET_MODAL_04', modal04 : true})}
        >
          <LineChartOutlined className = ' text-xl lg:text-2xl flex cursor-pointer '/>
        </div>
        
        <div 
          className = "flex items-center justify-center text-white p-3 text-center mb-5 md:w-16 md:h-16 w-12 h-12 shadow-lg rounded-full cursor-pointer bg-gray-800 hover:bg-sky-600"
          onClick   = {() => clickInfo()}
        >
          <InfoFilledIcon className = ' text-xl lg:text-2xl flex cursor-pointer '/>
        </div>
      </div>
    </Affix>
  );
}

export default Sidemenu01;