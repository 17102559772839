//Dependencies
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Select } from 'antd';
import ProgressiveImage from 'react-progressive-graceful-image';
//Components
import CameraCapture from '../camera/CameraCapture';
//Main Components
import Drawer from '../../../components/Drawer';
//Database
import list01 from '../../../databases/list01';
//Scripts
import { PointInCircle } from '../../../scripts/Helpers';
//Actions
import { UploadReport } from '../../../store/actions/ReportActions';

const { Option } = Select;

/* DRAWER DE REPORTE - REPORT */
const Drawer01 = (props) =>
{
  const { liveLocation, location, user, visible } = props;

  const dispatch = useDispatch();
  const [damageType, damgeType] = useState(null);
  
  const reportSnapshot = useSelector(state => state.reports.reportSnapshot);
  const uploading = useSelector(state => state.app.uploading);

  const closeDrawer = () =>
  {
    const scroller = document.querySelector("#scroller");   
    scroller.scrollTo(0,0);
   
    dispatch({type: 'SET_DRAWER_01', drawer01 : false});
    dispatch({type: 'SET_REPORT_SNAPSHOT', reportSnapshot : null});
  };

  const sendReport = () =>
  {
    let date = new Date().getTime();

    let data =
    {
      date            : date,
      image           : reportSnapshot,
      location        : location,
      user            : user,
      type            : damageType,
    }

    dispatch(UploadReport(data));
    damgeType(null);
  }

  return(
    <Drawer
      height    = {80}
      visible   = {visible}
      onClose   = {() => closeDrawer()}      
    >
      <div className = "w-full bg-white select-none">
        <div className = "flex bg-white"  >
          <div className = "flex items-center flex-col text-center px-6 md:px-12 md:w-full sm:w-full w-full" >
            <div className = "w-full">
              <h2 className = "text-xl font-bold text-gray-800 uppercase md:text-4xl mt-3 mb-1">
                NUEVO APORTE
              </h2>
            </div>

            <div className = "w-full">
              <h2 className = "text-xl font-bold text-gray-800 uppercase md:text-2xl mt-3 mb-1">
                TIPO DE DAÑO
              </h2>
            </div>

            {!damageType &&
              <h1 className = "flex justify-center text-base font-semibold font-mono my-0 text-red-500">
                DEBES SELECCIONAR TIPO DE DAÑO
              </h1>
            }

            <Select
              placeholder = 'SELECCIONAR DAÑO' 
              style = {{ width: 350 }}
              onChange = {(e) => damgeType(e)}
              defaultValue = {damageType}
            >
              {list01 && Object.keys(list01).map((m1, i) => 
              {
                let info = list01[m1];

                return(
                  <Option key = {i} value = {info.value}>{info.text.toUpperCase()}</Option>                  
                )
              })
            }     
            </Select>

            <div className = "w-full">
              <h2 className = "text-xl font-bold text-gray-800 uppercase md:text-2xl mt-3 mb-1">
                FOTOGRAFÍA DEL DAÑO
              </h2>
            </div>

            <React.Fragment>
              {!liveLocation && !location &&
                <h1 className = "flex justify-center text-base font-semibold font-mono my-0 text-red-500">
                  SE NECESITA TU UBICACIÓN PARA PODER REPORTAR
                </h1>
              }
              
              {(liveLocation && location) && PointInCircle(location ,liveLocation, 30) &&
                <h1 className = "flex justify-center text-base font-semibold font-mono my-0 text-green-500">
                  ESTÁS DENTRO DEL RANGO DE 30m PARA PODER REPORTAR
                </h1>
              }

              {(liveLocation && location) && !PointInCircle(location ,liveLocation, 30) &&
                <h1 className = "flex justify-center text-base font-semibold font-mono my-0 text-red-500">
                  NO ESTÁS DENTRO DEL RANGO DE 30m PARA PODER REPORTAR
                </h1>
              }              
            </React.Fragment>

            {reportSnapshot &&
              <div className = "py-2">
                <ProgressiveImage 
                  src = {reportSnapshot} 
                  placeholder = {'https://firebasestorage.googleapis.com/v0/b/bettercityio.appspot.com/o/extra%2Fblurimage.jpg?alt=media&token=1f2ea1d6-8609-4225-abf0-77a31f10b732'}
                >
                  {(src) => {
                    return (
                      <img 
                        src = {src} 
                        style = {{ width: window.innerWidth * 0.9, height: window.innerWidth * 0.9, maxWidth: 400, maxHeight: 400, margin: 'auto'}}
                        alt = 'report'
                        className ='shadow-xl'
                      />
                    );
                  }}
                </ProgressiveImage>

                <div className = 'flex items-center justify-evenly w-full mt-3 mx-0 max-w-[400px]'>
                  {/*
                  <div 
                    className = "m-0 text-white text-center inline-flex items-center justify-center text-base w-10 h-10 md:text-xl md:w-12  md:h-12 lg:text-xl lg:w-12 lg:h-12 shadow-lg rounded-full bg-gray-800 hover:bg-sky-600 cursor-pointer"
                    onClick = {() => dispatch({type: 'SET_REPORT_SNAPSHOT', reportSnapshot : null})}
                  >
                    <i className = "fas fa-camera text-white m-0"></i>
                  </div>
                  */}

                  <div 
                    className = "m-0 text-white text-center inline-flex items-center justify-center text-base w-10 h-10 md:text-xl md:w-12  md:h-12 lg:text-xl lg:w-12 lg:h-12 shadow-lg rounded-full bg-red-600 hover:bg-red-800 cursor-pointer"
                    onClick = {() => dispatch({type: 'SET_REPORT_SNAPSHOT', reportSnapshot : null})}
                  >
                    <i className = "fas fa-trash text-white m-0"></i>
                  </div>
                </div>
              </div>
            }

            {!reportSnapshot &&
              <div
                className = "py-2"
              >
                <CameraCapture/>
              </div>
            }                       

            <div className = 'flex items-center justify-evenly'>
              
              <div 
                className = 'flex items-center justify-evenly'
              >
                <button 
                  className = {(reportSnapshot && damageType && location && liveLocation && PointInCircle(location ,liveLocation, 30)) ? "w-full mt-1 bg-sky-600 hover:bg-sky-900 rounded px-6 py-2 text-lg text-white tracking-wide font-semibold font-mono" : "w-full mt-1 bg-gray-600 rounded px-6 py-2 text-lg text-gray-200 tracking-wide font-semibold font-mono"} 
                  disabled = {(!reportSnapshot || !damageType || !location || !liveLocation || !PointInCircle(location ,liveLocation, 30)) ? true : false}
                  onClick = {() => sendReport()}
                >
                  ENVIAR APORTE
                </button>
              </div>
            </div>

            {uploading &&
              <div className = "fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-gray-700 opacity-90 flex flex-col items-center justify-center">
                <div className = "loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4"></div>
                <h2 className = "text-center text-white text-2xl font-semibold font-mono">
                  SUBIENDO APORTE...
                </h2>
                <p className = "lg:w-1/3 text-center text-white font-mono text-xl">
                  Se está subiendo el aporte a nuestra base de datos.
                </p>
                <p className = "lg:w-1/3 text-center text-white font-mono text-xl">
                  Gracias por tu aporte.
                </p>
              </div>
            }
            
            <button
              className = 'bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 border border-red-700 rounded lg:w-1/4 w-2/3 text-lg tracking-widest md:mb-5 mt-5'
              onClick={() => 
                {
                  closeDrawer();
                }}
            >
              CERRAR
            </button>      
          </div>
        </div>
      </div>
    </Drawer>
  );
}

export default Drawer01;