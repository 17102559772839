//Dependecies
import React, { useEffect, useCallback, useState } from 'react';
import { toastr } from 'react-redux-toastr';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from "react-router-dom";
import { isMobileOnly } from 'react-device-detect';
import { CircleMarker } from "react-leaflet";
//Databases
import dimensions from '../../databases/dimensions';
//Components
import Map01 from '../components/maps/Map01';
import Menu01 from '../components/menus/Menu01';
import Menu02 from '../components/menus/Menu02';
import Modal01 from '../components/modals/Modal01';
import Modal02 from '../components/modals/Modal02';
import Modal04 from '../components/modals/Modal04';
import Sidemenu01 from '../components/sidemenus/Sidemenu01';
//Actions
import { GetMarkers } from '../../store/actions/LoadingActions';
import { AddView } from '../../store/actions/ReportActions';
import { LoadReport } from '../../store/actions/NotificationsActions';

const MainRouter = () =>
{
  const dispatch = useDispatch();
  const navigate = useNavigate(); 
  let { id } = useParams();

  const loading = useSelector(state => state.app.loading);

  const location = useSelector(state => state.map.location);
  const center = useSelector(state => state.map.center);
  const zoom = useSelector(state => state.map.zoom);
  const layers = useSelector(state => state.map.layers); 
  const group = useSelector(state => state.map.group); 
  const filter = useSelector(state => state.map.filter); 

  const markersDB = useSelector(state => state.markers.markersDB);
  const selected = useSelector(state => state.markers.selected);
  const last = useSelector(state => state.markers.last);

  const modal02 = useSelector(state => state.modals.modal02);

  const month = useSelector(state => state.statistics.month);
  const year = useSelector(state => state.statistics.year);

  const [ markerList01, setMarkerList01 ] = useState(null);
  
  /* DIMENSIONS */
  const height = window.innerHeight;
  const width = window.innerWidth;

   /* SET FILTER YEAR AND MONTH */
  useEffect(() => 
  {
    let monthDelta = 0;
    let yearDelta = 2;
    let currentDate = new Date().getTime();
    let dateFormat = new Date(currentDate);

    let currentYear = dateFormat.getFullYear();
    let currentMonth = dateFormat.getMonth();

    let filterMonth = currentMonth - monthDelta < 0 ? currentMonth - monthDelta + 12 : currentMonth - monthDelta;
    let filterYear = currentMonth - monthDelta < 0 ? currentYear - 1 - yearDelta: currentYear - yearDelta;

    dispatch({type: 'SET_STATISTICS_YEAR', year : filterYear});
    dispatch({type: 'SET_STATISTICS_MONTH', month : filterMonth + 1});

  }, [dispatch]);
  
  /* LOADING MARKERS */
  useEffect(() => 
  {
    dispatch({type: 'SET_MAP_MARKER_GROUP', group : false});
    dispatch(GetMarkers());
  }, [dispatch]);

  /* CHECK ID */
  useEffect(() => 
  {
    dispatch({type: 'LOADING', loading : false});
  }, [dispatch]);

  /* CHECK ID */
  useEffect(() => 
  {
    if(markersDB && id)
    {
      try 
      {
        const markerID = Object.keys(markersDB).find(f1 => markersDB[f1].id === parseInt(id));
        
        if(markerID)
        {
          let info = markersDB[markerID];

          dispatch({type: 'SET_CENTER', center : info.location});     
        
          setTimeout(() => 
          {
            dispatch({type: 'SET_MARKERS_SELECTED', selected : info});
            dispatch({type: 'SET_LAST', last : info});
          }, 200); 
    
          setTimeout(() => 
          {
            dispatch({type: 'SET_MODAL_02', modal02 : true});
            dispatch(AddView(info));
            LoadReport(info.id, info.views, isMobileOnly);            
          }, 400);
    
          if(zoom < 14 )
          {
            setTimeout(() => 
            {
              dispatch({type: 'SET_ZOOM', zoom : 14});
            }, 400);
          }
        }
        else
        {
          toastr.error("APORTE VIAL", 'PÁGINA NO ENCONTRADA');
        }      
      }
      catch(error)
      {
        console.log(error)
      }
    }
  }, [dispatch, markersDB, id]);  

  const clickMarker = useCallback((info) => 
  {
    navigate(`/${info.id}`); 
  }, [navigate]); 

   /* ALL MARKERS */
   useEffect(() => 
   {
     let repair = 0;
     let nonrepair = 0;
     let repeated = 0;
 
     dispatch({type: 'LOADING', loading : true});
 
     let markerArray01 = [];
 
     if(markersDB)
     {
      try
      {        
        for(let i = 0; i < Object.keys(markersDB).length; i++)
        {
          let info = markersDB[Object.keys(markersDB)[i]];
  
          let reportsLength = Object.keys(info.reports).length;
          let lastReport = Object.keys(info.reports).find(f1 => info.reports[f1].id === reportsLength);
  
          //let markerColor = info.reports[lastReport].type === 'damage' || info.reports[lastReport].type === 'update' ? '#f2261c' : '#1cf244';          

          let markerColor;

          switch(info.reports[lastReport].type) 
          {
            case 'damage':
              markerColor = '#f2261c'
              break;
            case 'update':
              markerColor = '#FFD966'
              break;
            default:
              markerColor = '#1cf244'
          }
          
          let markerDate = new Date(info.id);
          let markerYear = markerDate.getFullYear();
          let markerMonth = markerDate.getMonth() +1;   

          if(markerYear === year)
          {
            if(markerMonth >= month)
            {
              info.reports[lastReport].type === 'damage' || info.reports[lastReport].type === 'update' ? nonrepair = nonrepair + 1 : repair = repair + 1;
  
              if(reportsLength > 2)
              {
                repeated = repeated + 1;
              }
  
              let marker =
              <CircleMarker
                className   =  {isMobileOnly ? '' : 'markerShadow'}
                key         = {i}
                center      = {info.location}
                fill        = "true"
                color       = {isMobileOnly ? '#000' : '#fff'}
                fillColor   = {markerColor}
                opacity     = {1}
                fillOpacity = {1}
                weight      = {2}
    
                onClick     = {() => clickMarker(info)}
              />
  
              markerArray01.push({...marker});
            }            
          }

          if(markerYear > year)
          {
            info.reports[lastReport].type === 'damage' || info.reports[lastReport].type === 'update' ? nonrepair = nonrepair + 1 : repair = repair + 1;
  
            if(reportsLength > 2)
            {
              repeated = repeated + 1;
            }

            let marker =
            <CircleMarker
              className   =  {isMobileOnly ? '' : 'markerShadow'}
              key         = {i}
              center      = {info.location}
              fill        = "true"
              color       = {isMobileOnly ? '#000' : '#fff'}
              fillColor   = {markerColor}
              opacity     = {1}
              fillOpacity = {1}
              weight      = {2}
  
              onClick     = {() => clickMarker(info)}
            />

            markerArray01.push({...marker});
          }
        };
  
        setMarkerList01(markerArray01);
  
        dispatch({type: 'SET_STATISTICS_REPAIR', repair : repair});
        dispatch({type: 'SET_STATISTICS_NONREPAIR', nonrepair : nonrepair});
        dispatch({type: 'SET_STATISTICS_REPEATED', repeated : repeated});   
      } 
      catch (error) 
      {
        console.log(error);
      }
     }     
 
     dispatch({type: 'LOADING', loading : false});

   }, [dispatch, clickMarker, markersDB, year, month]);   

  if(loading) return(
    <Menu02
      height        = {Math.round(dimensions.menu * height / 100)}        
      visible       = {true}
    />
  )

  return(
    <React.Fragment>
      {markersDB && center && zoom && markerList01 &&
        <Map01
          location      = {location}
          height        = {window.innerHeight}
          center        = {center}
          zoom          = {zoom}
          filter        = {filter}
          paddingTop    = {0}
          paddingBottom = {Math.round((dimensions.menu) * height / 100)}
          visible       = {true}
          markers       = {markersDB}
          layers        = {layers}
          group         = {group}
          last          = {last}
          markerList01  = {markerList01}
        />
      }

      <Modal01
        height        = {Math.round(dimensions.modal01 * height / 100)}
        width         = {Math.round(95 * width / 100)}
        visible       = {true}
        group         = {group}

        isMobile      = {isMobileOnly}
      />

      {selected && modal02 &&
        <Modal02
          height        = {Math.round(dimensions.modal01 * height / 100)}
          width         = {Math.round(95 * width / 100)}
          visible       = {modal02}
          info          = {selected}
        />
      }

      <Modal04
        height        = {Math.round(dimensions.modal01 * height / 100)}
        width         = {Math.round(95 * width / 100)}
        visible       = {true}

        isMobile      = {isMobileOnly}
      />

      <Sidemenu01
        height        = {Math.round((dimensions.menu + dimensions.footer) * height / 100)}
        layers        = {layers}
        group         = {group}
        visible       = {true}
        info          = {last}
      />    
      
      <Menu01
        height        = {Math.round(dimensions.menu * height / 100)}
        filter        = {filter}
        visible       = {true}

        month         = {month}
        year          = {year}
      />
    </React.Fragment>
  );
}

export default MainRouter;