//Dependecies
import React from 'react';
import { Link } from 'react-router-dom';
import { isMobileOnly } from 'react-device-detect';

const InfoPage = () =>
{
  return(
    <React.Fragment>
      <section className = "relative bg-blue-50">
        <div 
          className = "relative pt-16 pb-32 flex content-center items-center justify-cente " 
          style = 
            {{
              minHeight : '75vh',
            }}
        >
          <div 
            className = "absolute top-0 w-full h-full"
          >
            <img
              src = "https://firebasestorage.googleapis.com/v0/b/bettercityio.appspot.com/o/info%2Finfo-image-01.jpg?alt=media&token=8ed24c04-3555-423c-8126-e203468c5c8b"
              className = "absolute top-0 w-full h-full object-center object-cover"
              alt = ""
            />
            <span             
              className = "w-full h-full absolute opacity-75 bg-black"
            />
          </div>

          <div className = "container relative mx-auto">
            <div className = "items-center flex flex-wrap">
              <div className = "w-full lg:w-6/12 px-4 ml-auto mr-auto text-center">
                <h1 className = "text-white font-semibold text-3xl lg:text-6xl sm:text-4xl">
                  APORTE VIAL
                </h1>
                <p className = "mt-4 text-white text-base md:text-xl ">
                  En <b>APORTE VIAL</b> podrás registrar todo tipo de daños desde tu dispositivo móvil y podrás verlos en cualquier dispositivo.
                </p>

                <p className = "mt-4 text-white text-base md:text-xl ">
                  Se parte de nuestra <b>COMUNIDAD</b> que quiere ayudar a mejorar nuestra Ciudad.
                </p>
              </div>
            </div>
          </div>

          <div 
            className = "top-auto bottom-0 left-0 right-0 absolute" 
            style = 
            {{
              transform : 'translateZ(0px)',
              height    : '75px'
            }}
          >
            <svg 
              className = "absolute  bottom-0 overflow-hidden"
              xmlns = "http://www.w3.org/2000/svg" 
              preserveAspectRatio = "none" 
              version = "1.1" 
              viewBox = "0 0 2560 100"
              x = "0"
              y = "0"
            >
              <polygon 
                className = "text-blue-50 fill-current"
                points = "2560 0 2560 100 0 100"
              />
            </svg>
          </div>  
        </div>
        
        <section className = "pb-10 bg-blue-50 -mt-24">
          <div className = "container mx-auto px-4 max-w-6xl">
            <div className = "flex flex-wrap">
              <div className = "lg:pt-12 pt-6 w-full md:w-4/12 px-4 text-center">
                <div className = "relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                  <div className = "px-4 py-5 flex-auto">
                    <div className = "text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-red-400 text-lg">
                      <i className = "fas fa-users"></i>
                    </div>
                    <h6 className = "text-xl font-semibold">
                      Comunidad
                    </h6>
                    <p className = "mt-2 mb-4 text-blueGray-500">
                      Se parte de nuestra <b>COMUNIDAD</b> que quiere ayudar a que nuestra ciudad se vea mas linda.
                    </p>
                  </div>
                </div>
              </div>

              <div className = "w-full md:w-4/12 px-4 text-center">
                <div className = "relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                  <div className = "px-4 py-5 flex-auto">
                    <div className = "text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-sky-400 text-lg">
                      <i className = "fas fa-camera"></i>
                    </div>
                    <h6 className = "text-xl font-semibold">
                      Fotografía
                    </h6>
                    <p className = "mt-2 mb-4 text-blueGray-500">
                      Todos los daños cuentan con foto, ubicación y fecha.
                      Cada reporte cuenta con un historial completo de daños y reparaciones.
                    </p>
                  </div>
                </div>
              </div>

              <div className = "pt-6 w-full md:w-4/12 px-4 text-center">
                <div className = "relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                  <div className = "px-4 py-5 flex-auto">
                    <div className = "text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-emerald-400 text-lg">
                      <i className = "fas fa-share"></i>
                    </div>
                    <h6 className = "text-xl font-semibold">
                      Fácil de compartir
                    </h6>
                    <p className = "mt-2 mb-4 text-blueGray-500">
                      Hecho para poder ser compartido con facilidad. Los daños son fáciles de reportar y de encontrar, ubicados en un mapa georeferenciado.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className = 'flex items-center justify-evenly'>
            <Link 
              className = 'flex items-center justify-evenly'
              target    = "_blank" 
              to        = {'/'}
            >
              <button 
                className = {"w-full mt-1 bg-sky-600 hover:bg-sky-900 rounded px-6 py-2 text-lg text-white tracking-wide font-semibold font-mono"} 
              >
                PÁGINA DE INICIO
              </button>
            </Link>
          </div>          

          <footer className = "relative  pt-2 pb-2 mt-1">
            <div className = "container mx-auto px-4">
              <div className = "flex flex-wrap items-center md:justify-between justify-center">
                <div className = "w-full md:w-full px-4 mx-auto text-center">
                  <div className = "text-sm text-blueGray-500 font-semibold py-1">
                    SÍGUENOS EN TWITTER

                    &nbsp;

                    <a 
                      href      = {isMobileOnly ? `twitter://user?screen_name=aportevial` : `https://www.twitter.com/aportevial`}
                      className = "text-blueGray-500 hover:text-blueGray-800"
                      target    = "_blank"
                      rel       = "noopener noreferrer"
                    >
                      <b>APORTE VIAL</b>
                    </a>

                    &nbsp;

                    <a 
                      className = "m-0 text-white text-center inline-flex items-center justify-center text-base w-10 h-10 md:text-xl md:w-12  md:h-12 lg:text-xl lg:w-12 lg:h-12 shadow-lg rounded-full bg-sky-600 hover:bg-sky-900 cursor-pointer"
                      target    = '_blank'
                      rel       = "noopener noreferrer"
                      href      = {isMobileOnly ? `twitter://user?screen_name=aportevial` : `https://www.twitter.com/aportevial`}
                      //onClick   = {() => infoNotification('facebook', isMobile)}
                    >
                      <i className = "fab fa-twitter text-white m-0"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </footer>
        </section>  
      </section>

    </React.Fragment>
  );
}

export default InfoPage;