//Dependencies
import React from 'react';
import { useDispatch } from 'react-redux';
import { DatePicker } from 'antd';
import moment from 'moment';

//Database
import list01 from '../../../databases/list01';

/* UBICACIÓN DE EN MAPA - QUITO */
const Popover02 = (props) =>
{
  const dispatch = useDispatch();
  const { filter, year, month } = props;

  const clickFilter = (value) =>
  {
    dispatch({type: 'SET_LAST', last : null});
    dispatch({type: 'SET_FILTER', filter : value});
  }

  const dateChange = (date, dateString) =>
  {
    dispatch({type: 'SET_STATISTICS_YEAR', year : dateString !== ''? parseInt(dateString.split("-")[0]) : null});
    dispatch({type: 'SET_STATISTICS_MONTH', month : dateString !== ''? parseInt(dateString.split("-")[1]) : null});
  }

  return(
    <div>
      <h1 className = "flex justify-center mb-1 text-3xl text-blue-600 font-bold select-none">
        TIPO DE REPORTE
      </h1>

      <div className = "w-full max-w-2xl justify-center my-3">
        <DatePicker
          defaultValue = {moment(`${year}/${month + 1 > 9 ? parseInt(month) : '0' +  (parseInt(month) )}`, "YYYY/MM")}
          className = "flex text-xl font-semibold font-mono w-[300px]"
          placeholder = 'desde que mes' 
          picker = "month"
          onChange = {dateChange}
        />
      </div>

      <div 
        className = "h-[40px] w-[300px] flex justify-center bg-transparent hover:bg-sky-500 text-gray-800 font-semibold  border-2 border-gray-800 rounded my-1 cursor-pointer select-none hover:text-white"
        onClick   = {() => clickFilter(null)}
      >
        <h1 
          className = 'flex items-center m-0'
        >
          TODOS LOS REPORTES
        </h1>
      </div>      

      {list01 && Object.keys(list01).map((m1, i) => 
        {          
          let info = list01[m1];

          if(!info.visible) return null;
          return(
            <div
              key       = {i}
              className = {`h-[40px] w-[300px] flex justify-center uppercase bg-transparent hover:bg-sky-500 ${info.value === filter ? 'bg-sky-300' : ''} text-gray-800 font-semibold hover:text-white border-2 border-gray-800 rounded my-1 cursor-pointer select-none `}
              onClick   = {() => clickFilter(info.value)}
            >
              <h1 
                className = 'flex items-center m-0'
              >
                {info.text}
              </h1>
            </div>
          )
          
        })
      }
    </div>
  );
}

export default Popover02;