//Dependecies
import React from 'react';

const NotFoundPage = () =>
{
  return(
    <React.Fragment>
      <section className = "relative bg-blue-50">
        <div 
          className = "text-white min-h-screen flex items-center bg-gradient-to-r from-cyan-500 to-blue-500"
        >
          <div className="container mx-auto p-4 flex flex-wrap items-center">          
            <div className="w-full md:w-7/12 text-center md:text-left p-4">
              <div className="text-6xl font-medium mb-4">
                APORTE VIAL
              </div>
              <div className="text-4xl font-medium">ERROR 404</div>
              <div className="text-xl md:text-3xl font-medium mb-4">
                PÁGINA NO ENCONTRADA
              </div>
              <div className="text-lg mb-6">
                SE INGRESÓ MAL EL ENLACE
              </div>
              <a 
                href = "/" 
                className=" bg-transparent hover:bg-white text-white font-semibold hover:text-grey-800 py-2 px-4 border-2 border-white hover:border-transparent rounded "
              >
                PÁGINA PRINCIPAL
              </a>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

export default NotFoundPage;