//Dependecies
import React from 'react';
import { Link } from 'react-router-dom';

const PhoneOnlyPage = () =>
{
  return(
    <React.Fragment>
      <div className = "fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-gray-700 opacity-75 flex flex-col items-center justify-center">
        <div 
          className = "m-0 text-white text-center inline-flex items-center justify-center text-4xl my-5"
        >
          <i className = "fas fa-mobile-alt text-white m-0"></i>
        </div>
        <h2 className = "text-center text-white text-xl font-semibold font-mono px-1">
          APP DISPONIBLE SOLO EN DISPOSITIVOS MÓBILES
        </h2>
        <p className = "text-center text-white font-mono">
          Solo se pueden hacer aportes de daños en dispositivos móbiles
        </p>

        <Link 
          className = 'flex items-center justify-evenly'
          to        = {'/'}
        >
          <button className ="text-sm lg:text-base bg-transparent hover:bg-sky-500 text-white font-semibold hover:text-white py-2 px-4 border-2 border-white hover:border-transparent rounded">
            PÁGINA PRINCIPAL
          </button>
        </Link>
      </div>
    </React.Fragment>
  );
}

export default PhoneOnlyPage;