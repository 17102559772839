import { toastr } from 'react-redux-toastr';

export const GetLocation = () => async (dispatch) => 
{
  let location;

  try 
  {
    navigator.geolocation.getCurrentPosition(
      function(position) 
        {
          dispatch({type: 'SET_CENTER', center : [0,0]});

          location = [position.coords.latitude.toFixed(6) , position.coords.longitude.toFixed(6)];

          dispatch({type: 'SET_LOCATION', location : location});
          dispatch({type: 'SET_CENTER',center : location});

          dispatch({type: 'SET_ZOOM', zoom : 16});
        },
      function(error)
        {
          switch(error.code) 
          {
            case error.PERMISSION_DENIED:
              toastr.error("APORTE VIAL", 'SE DEBE ACTIVAR GEOLOCALIZACIÓN');
              break;
            case error.POSITION_UNAVAILABLE:
              toastr.error("APORTE VIAL", 'NO EXISTE INFORMACIÓN');
              break;
            case error.TIMEOUT:
              toastr.error("APORTE VIAL", 'GPS NO PUDO OBTENER UBICACIÓN');
              break;
            case error.UNKNOWN_ERROR:
              console.log("APORTE VIAL", 'ERROR ENCONTRADO PARA OBTENER UBICACIÓN');
              break;
            default:
            toastr.error("APORTE VIAL", 'NO SE PUEDE OBTENER UBICACIÓN');
          }

          dispatch({type: 'SET_LOCATION',location : [-0.144236, -78.475527]});
          dispatch({type: 'SET_CENTER', center : [-0.144236, -78.475527]});
          dispatch({type: 'SET_ZOOM',zoom : 15});
        },
        {
          maximumAge          : 0,
          timeout             : 500, 
          enableHighAccuracy  : true
        }
      );
  } 
  catch (error) 
  {
    console.log(error);
  }
}

export const GetLiveLocation = () => async (dispatch) => 
{
  let liveLocation;
  let counter = 0;

  try 
  {
    let watchID = navigator.geolocation.watchPosition(
      function(position) 
        {
          liveLocation = [position.coords.latitude.toFixed(6) , position.coords.longitude.toFixed(6)];
    
          dispatch({type: 'SET_LIVE_LOCATION', liveLocation : liveLocation});

          if(counter === 0)
          {
            dispatch({type: 'SET_LOCATION', location : liveLocation});
            dispatch({type: 'SET_CENTER', center : liveLocation});
            
            setTimeout(() => 
            {
              dispatch({type: 'SET_ZOOM', zoom : 19});
            }, 500);

            toastr.success("APORTE VIAL", 'UBICACIÓN CONTINUA SE DESACTIVARÁ EN 5 MINUTOS');
          }

          counter++;
        },
      function(error)
        {
          switch(error.code) 
          {
            case error.PERMISSION_DENIED:
              toastr.error("APORTE VIAL", 'SE DEBE ACTIVAR GEOLOCALIZACIÓN');
              break;
            case error.POSITION_UNAVAILABLE:
              toastr.error("APORTE VIAL", 'NO EXISTE INFORMACIÓN');
              break;
            case error.TIMEOUT:
              toastr.error("APORTE VIAL", 'CONEXIÓN LENTA DE INTERNET');
              break;
            case error.UNKNOWN_ERROR:
              console.log("APORTE VIAL", 'ERROR ENCONTRADO PARA OBTENER UBICACIÓN');
              break;
            default:
            toastr.error("APORTE VIAL", 'NO SE PUEDE OBTENER UBICACIÓN');
          }

          dispatch({type: 'SET_LOCATION', location : [-0.144236, -78.475527]});
          dispatch({type: 'SET_CENTER', center : [-0.144236, -78.475527]});
          dispatch({type: 'SET_ZOOM',zoom : 15});
        },
        {
          enableHighAccuracy: true,
          timeout: 1000,
          //maximumAge: 0
        }
      );

    setTimeout(() => 
    {
      navigator.geolocation.clearWatch(watchID);
      
      toastr.error("APORTE VIAL", 'SE DESACTIVÓ LA UBICACIÓN CONTINUA');
      dispatch({type: 'SET_LIVE_LOCATION', liveLocation : null});
      dispatch({type: 'SET_LOCATION', location : null});

      dispatch({type: 'SET_DRAWER_01', drawer01 : false});
    }, 300000);

    dispatch({type: 'SET_PIN_MOVED', pinIsMoved : true});
  } 
  catch (error) 
  {
    console.log(error);
  }
}