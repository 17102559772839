//Dependencies
import React from 'react';
import { Modal } from 'antd';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { EnvironmentOutlined, FilterOutlined, HomeOutlined, GroupOutlined, UngroupOutlined, ZoomInOutlined } from '@ant-design/icons';
import { InfoFilledIcon } from '../../../icons/icons';
//Actions
//import { infoNotification } from '../../../store/actions/NotificationsActions';

/* MODAL DE INFORMACIÓN - SOLO VIEW */
const Modal01 = (props) =>
{
  const { group, height,  width, isMobile } = props; 
  const dispatch = useDispatch();
  const modal01 = useSelector(state => state.modals.modal01);

  return(
    <Modal
      title     = {null}
      closable  = {false}
      visible   = {modal01}
      onCancel  = {() => dispatch({type: 'SET_MODAL_01', modal01 : false})}
      width     = {Math.min(height, width)}
      footer    = {null}
      style     = {{ top: 20 }}
      bodyStyle = {{ borderRadius: '0px 0px 15px 15px !important'}}
      className = 'rounded-lg'
    >
      <div className = "max-w-md mx-auto px-[24px]">
        <div className = "divide-y divide-gray-300/50">
          <p className = "flex justify-center text-xl font-semibold font-mono">INSTRUCCIONES</p>

          <div className = "py-2 text-base text-gray-600 ">
            <ul className = "space-y-4">
              <li className = "flex items-center">
                <div className = " text-center inline-flex items-center justify-center w-9 h-9 rounded-full bg-gray-200 text-gray-500 border-2 border-gray-500">
                  <HomeOutlined />
                </div>
                <p className = "ml-4 mb-0">
                  <span className = "text-sm md:text-base lg:text-base font-extrabold font-mono text-gray-900">IR A INICIO</span>
                </p>
              </li>  

              <li className = "flex items-center">
                <div className = " text-center inline-flex items-center justify-center w-9 h-9 rounded-full bg-gray-200 text-gray-500 border-2 border-gray-500">
                  <EnvironmentOutlined />
                </div>
                <p className = "ml-4 mb-0">
                  <span className = "text-sm md:text-base lg:text-base font-extrabold font-mono text-gray-900">UBICACIÓN CON GPS Y POR SECTOR</span>
                </p>
              </li>

              <li className = "flex items-center">
                <div className = " text-center inline-flex items-center justify-center w-9 h-9 rounded-full bg-gray-200 text-gray-500 border-2 border-gray-500">
                  <FilterOutlined />
                </div>
                <p className = "ml-4 mb-0">
                  <span className = "text-sm md:text-base lg:text-base font-extrabold font-mono text-gray-900">FILTRO POR TIPO DE REPORTE</span>
                </p>
              </li>

              <li className = "flex items-center">
                <div className = " text-center inline-flex items-center justify-center w-9 h-9 rounded-full bg-gray-200 text-gray-500 border-2 border-gray-500">
                  <ZoomInOutlined />
                </div>
                <p className = "ml-4 mb-0">
                  <span className = "text-sm md:text-base lg:text-base font-extrabold font-mono text-gray-900">ZOOM AL ÚLTIMO REPORTE SELECCIONADO</span>
                </p>
              </li>

              <li className = "flex items-center">
                <div className = " text-center inline-flex items-center justify-center w-9 h-9 rounded-full bg-gray-200 text-gray-500 border-2 border-gray-500">
                  <i className = "fas fa-layer-group"></i>
                </div>
                <p className = "ml-4 mb-0">
                  <span className = "text-sm md:text-base lg:text-base font-extrabold font-mono text-gray-900">CAMBIAR ENTRE SATÉLITE Y MAPA</span> 
                </p>
              </li>
              
              <li className = "flex items-center">
                <div className = " text-center inline-flex items-center justify-center w-9 h-9 rounded-full bg-gray-200 text-gray-500 border-2 border-gray-500">
                  <InfoFilledIcon />
                </div>
                <p className = "ml-4 mb-0">
                  <span className = "text-sm md:text-base lg:text-base font-extrabold font-mono text-gray-900">SE ABRE ESTA VENTANA</span> 
                </p>
              </li>

              {group && 
                <li className = "flex items-center">
                  <div className = " text-center inline-flex items-center justify-center w-9 h-9 rounded-full bg-gray-200 text-gray-500 border-2 border-gray-500">
                    <UngroupOutlined />
                  </div>
                  <p className = "ml-4 mb-0">
                    <span className = "text-sm md:text-base lg:text-base font-extrabold font-mono text-gray-900">DESAGRUPAR APORTES</span> 
                  </p>
                </li>
              }

              {!group && 
                <li className = "flex items-center">
                  <div className = " text-center inline-flex items-center justify-center w-9 h-9 rounded-full bg-gray-200 text-gray-500 border-2 border-gray-500">
                    <GroupOutlined />
                  </div>
                  <p className = "ml-4 mb-0">
                    <span className = "text-sm md:text-base lg:text-base font-extrabold font-mono text-gray-900">AGRUPAR APORTES</span> 
                  </p>
                </li>
              }
            </ul>
            
            <div className = 'flex items-center justify-evenly'>
              <Link 
                className = 'flex items-center justify-evenly'
                target    = "_blank" 
                to        = {'/info'}
              >
                <button className = "text-sm lg:text-base bg-transparent hover:bg-sky-500 text-gray-800 font-semibold hover:text-white py-2 px-4 border-2 border-gray-500 hover:border-transparent rounded">
                  INFORMACIÓN
                </button>
              </Link>

              {/* TODAVÍA NO SE PONE LA OPCIÓN PARA REPORTAR */}
              {/* 
                <Link 
                  className = 'flex items-center justify-evenly'
                  target    = "_blank" 
                  to        = {'/app'}
                >
                  <button className = "text-sm lg:text-base bg-transparent hover:bg-sky-500 text-gray-800 font-semibold hover:text-white py-2 px-4 border-2 border-gray-500 hover:border-transparent rounded">
                    REPORTAR
                  </button>
                </Link>
              */}

              {/* 
              <div 
                className = 'flex items-center justify-evenly'
                target    = "_blank" 
                to        = {'/app'}
              >
                <button 
                  className = "text-sm lg:text-base bg-gray-600 rounded px-4 py-2 text-lg text-gray-200 tracking-wide font-semibold font-mono"
                  disabled = {true}
                >
                  REPORTAR
                </button>
              </div>
              */}

              {/* TODAVÍA NO HAY BLOG */}
              {/*
                <Link 
                  className = 'flex items-center justify-evenly'
                  target    = "_blank" 
                  to        = {'/info'}
                  //onClick   = {() => infoNotification('mas info', isMobile)}
                >
                  <button className ="text-sm lg:text-base bg-transparent hover:bg-sky-500 text-gray-800 font-semibold hover:text-white py-2 px-4 border-2 border-gray-500 hover:border-transparent rounded">
                    BLOG
                  </button>
                </Link>
               */}
            </div>           

            {/* NOTA DICIENDO Q TODAVÍA NO SE PUEDE REPORTAR */}
            <div className = 'mt-2 flex items-center justify-center'>
              <p className = "my-1 text-center text-base md:text-2xl text-gray-900 font-extrabold font-mono text-blue-500">
                PRONTO SE PODRÁ REPORTAR
              </p>
            </div>
            
            <div className = 'mt-2 flex items-center justify-center'>
              <p className = "my-4 text-center text-sm md:text-base text-gray-900">
                SÍGUENOS EN :
              </p>

              &nbsp; 

              <a 
                className = "m-0 text-white text-center inline-flex items-center justify-center text-base w-10 h-10 md:text-xl md:w-12  md:h-12 lg:text-xl lg:w-12 lg:h-12 shadow-lg rounded-full bg-gray-800 hover:bg-sky-600 cursor-pointer"
                target    = '_blank'
                rel       = "noopener noreferrer"
                href      = {isMobile ? `twitter://user?screen_name=aportevial` : `https://www.twitter.com/aportevial`}
                //onClick   = {() => infoNotification('facebook', isMobile)}
              >
                <i className = "fab fa-twitter text-white m-0"></i>
              </a>
            </div>
          </div>

          <div className = "pt-4 text-base font-semibold flex justify-center">
            <button 
              className = "bg-transparent hover:bg-sky-500 text-gray-800 font-semibold hover:text-white py-2 px-4 border-2 border-gray-500 hover:border-transparent rounded"
              onClick   = {() => dispatch({type: 'SET_MODAL_01', modal01 : false})}  
            >
              SALIR
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default Modal01;