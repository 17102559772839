const dimensions = 
{
  //Alturas en porcentaje
  navbar    : 5,
  menu      : 7,
  //footer    : 5,
  footer    : 0,
  modal01   : 60,

  //anchos en pixeles
  searchbar : 300
};

export default dimensions;