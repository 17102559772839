//Dependencies
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
//Pages
import InfoPage from './assets/modules/pages/InfoPage';
import NotFoundPage from './assets/modules/pages/NotFoundPage';
//Routers
import AppRouter from './assets/modules/routers/AppRouter';
import MainRouter from './assets/modules/routers/MainRouter';
import CheckRouter from './assets/modules/routers/CheckRouter';
//CSS
import './assets/css/input.css';
import './assets/css/overrides.css';
import './assets/css/custom.css';
import './index.css';
import 'leaflet/dist/leaflet.css';
import 'antd/dist/antd.css';

/* TEST ON PHONE */
//https://medium.com/@manujsdeveloper/debug-a-website-local-or-remote-hosted-on-an-android-mobile-device-ff2c43527be1
//chrome://inspect/#devices
//https://www.youtube.com/watch?v=wYDVXjUGdDo

//https://github.com/tailwindlabs/tailwindcss/blob/master/CHANGELOG.md

function App() {
  return (
    <Router>
      <Routes>
        <Route
          exact
          path    = '/404'
          element = {<NotFoundPage />}
        />

        <Route
          exact
          path    = '/'
          element = {<MainRouter />}
        />

        <Route
          exact
          path    = '/info'
          element = {<InfoPage />}
        />   

        <Route
          exact
          path    = '/app'
          element = {<AppRouter />}
        />

        <Route
          exact
          path    = '/aporte'
          element = {<CheckRouter />}
        />

        <Route
          exact
          path    = '/:id'
          element = {<MainRouter />}
        />

        <Route
          exact
          path    = '/app/:id'
          element = {<AppRouter />}
        />

        <Route 
          path = "*" 
          component = 
          {
            () => <NotFoundPage/>
          }
        />
      </Routes>      
    </Router>
  );
}

export default App;