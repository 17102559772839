//Dependencies
import React from 'react';
import { Affix } from 'antd';
import { InfoFilledIcon, LayerFilledIcon } from '../../../icons/icons';
//import { AimOutlined , GroupOutlined, UngroupOutlined, PlusOutlined } from '@ant-design/icons';
import { AimOutlined, PlusOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';

/* SIDEMENU */
const Sidemenu02 = (props) =>
{  
  //const { height, layers, visible, group, location } = props; 
  const { height, layers, visible, liveLocation, location } = props; 
  const dispatch = useDispatch();

  if(!visible) return null;

  const clickInfo = () =>
  {
    dispatch({type: 'SET_MODAL_03', modal03 : true});
    //infoNotification('info', isMobile);
  }; 

  const onOpenReport = () =>
  {
    dispatch({type: 'SET_DRAWER_01', drawer01 : true});
    
    setTimeout(() => 
    {
      //dispatch({type: 'SET_DRAWER_01', drawer01 : true});
    }, 200);
  };

  const reCenter = () =>
  {
    dispatch({type: 'SET_CENTER', center : liveLocation});
    dispatch({type: 'SET_LOCATION', location : liveLocation});

    setTimeout(() => 
    {
      dispatch({type: 'SET_ZOOM', zoom : 19});
    }, 500);
  }
  
  return(
    <Affix 
      style = {{ position: "fixed", bottom: height, right: 10, zIndex : 1000}}
    >
      <div>
        {liveLocation && location &&
          <div 
            className = "flex items-center justify-center text-white p-3 text-center mb-5 md:w-16 md:h-16 w-12 h-12 shadow-lg rounded-full cursor-pointer bg-gray-800 hover:bg-sky-600"
            onClick   = {() => onOpenReport()}
          >
            <PlusOutlined className = ' text-xl lg:text-2xl flex cursor-pointer '/>
          </div>
        }

        {!liveLocation && !location &&
          <div 
            className = "flex items-center justify-center text-black p-3 text-center mb-5 md:w-16 md:h-16 w-12 h-12 shadow-lg rounded-full cursor-pointer bg-gray-300 cursor-default"
          >
            <PlusOutlined className = ' text-xl lg:text-2xl flex '/>
          </div>
        }          

        {liveLocation &&
          <div 
            className = "flex items-center justify-center text-white p-3 text-center mb-5 md:w-16 md:h-16 w-12 h-12 shadow-lg rounded-full cursor-pointer bg-gray-800 hover:bg-sky-600"
            onClick   = {() => reCenter()}
          >
            <AimOutlined  className = ' text-xl lg:text-2xl flex cursor-pointer '/>
          </div>
        }

        {!liveLocation &&
          <div 
            className = "flex items-center justify-center text-black p-3 text-center mb-5 md:w-16 md:h-16 w-12 h-12 shadow-lg rounded-full cursor-pointer bg-gray-300 cursor-default"
          >
            <AimOutlined  className = ' text-xl lg:text-2xl flex cursor-default '/>
          </div>
        }

        <div 
          className = "flex items-center justify-center text-white p-3 text-center mb-5 md:w-16 md:h-16 w-12 h-12 shadow-lg rounded-full cursor-pointer bg-gray-800 hover:bg-sky-600"
          onClick   = {() => dispatch({type: 'SET_LAYERS', layers : !layers})}
        >
          <LayerFilledIcon className = ' text-xl lg:text-2xl flex cursor-pointer '/>
        </div>

        {/*
          {group &&
            <div 
              className = "flex items-center justify-center text-white p-3 text-center mb-5 md:w-16 md:h-16 w-12 h-12 shadow-lg rounded-full cursor-pointer bg-gray-800 hover:bg-sky-600"
              onClick = {() => dispatch({type: 'SET_MAP_MARKER_GROUP', group : !group})}
            >
              <UngroupOutlined className = ' flex text-xl lg:text-2xl flex cursor-pointer '/>
            </div>
          }

          {!group &&
            <div 
              className = "flex items-center justify-center text-white p-3 text-center mb-5 md:w-16 md:h-16 w-12 h-12 shadow-lg rounded-full cursor-pointer bg-gray-800 hover:bg-sky-600"
              onClick = {() => dispatch({type: 'SET_MAP_MARKER_GROUP', group : !group})}
            >
              <GroupOutlined className = ' flex text-xl lg:text-2xl flex cursor-pointer '/>
            </div>
          }
        */}

        <div 
          className = "flex items-center justify-center text-white p-3 text-center mb-5 md:w-16 md:h-16 w-12 h-12 shadow-lg rounded-full cursor-pointer bg-gray-800 hover:bg-sky-600"
          onClick   = {() => clickInfo()}
        >
          <InfoFilledIcon className = ' text-xl lg:text-2xl flex cursor-pointer '/>
        </div>
      </div>
    </Affix>
  );
}

export default Sidemenu02;