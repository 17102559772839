const list01 =
[    
  {
    text      : 'bache superficial', 
    value     : 1,
    visible   : true,
  },
  {
    text      : 'bache profundo', 
    value     : 2,
    visible   : true,
  },
  {
    text      : 'huecos', 
    value     : 12,
    visible   : true,
  },
  {
    text      : 'hundimiento - levantamiento', 
    value     : 3,
    visible   : true,
  },
  {
    text      : 'acumulación vegetación', 
    value     : 4,
    visible   : false,
  },   
  {
    text      : 'acumulación basura', 
    value     : 5,
    visible   : false,
  },
  {
    text      : 'basurero averiado', 
    value     : 6,
    visible   : true,
  },
  {
    text      : 'semáforo averiado', 
    value     : 7,
    visible   : false,
  },
  {
    text      : 'señalización faltante', 
    value     : 8,
    visible   : false,
  },
  {
    text      : 'señalización deficiente', 
    value     : 9,
    visible   : false,
  },
  {
    text      : 'tapa - rejilla/faltante', 
    value     : 10,
    visible   : true,
  },
  {
    text      : 'rejilla tapada', 
    value     : 11,
    visible   : true,
  },
  {
    text      : 'iluminación deficiente', 
    value     : 14,
    visible   : false,
  },
  {
    text      : 'cables caídos', 
    value     : 15,
    visible   : false,
  },
  {
    text      : 'cables colgados', 
    value     : 19,
    visible   : false,
  },
  {
    text      : 'fuga de agua', 
    value     : 16,
    visible   : true,
  },
  {
    text      : 'obstáculos', 
    value     : 17,
    visible   : false,
  },
  {
    text      : 'mal estado', 
    value     : 18,
    visible   : false,
  },
  {
    text      : 'accidente tránsito', 
    value     : 60,
    visible   : false,
  },
  {
    text      : 'vandalismo', 
    value     : 80,
    visible   : false,
  },  
  {
    text      : 'obra en ejecución', 
    value     : 200,
    visible   : false,
  },
  {
    text      : 'manifestaciones', 
    value     : 501,
    visible   : false,
  },
  {
    text      : 'cierre vial', 
    value     : 502,
    visible   : false,
  },
]

export default list01;