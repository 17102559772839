//Dependecies
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
//Actions
import { SignIn } from '../../store/actions/AuthActions';

const LogInPage = (props) =>
{
  const { confirmationResult } = props;
  const dispatch = useDispatch();

  const [validInput02, setValidInput02] = useState(false);

  const [code, setCode] = useState();

  const onChange01 = (e) =>
  {
    var phoneLength = /^\d{6}$/;
    
    setCode(e.target.value);

    if(e.target.value.match(phoneLength))
    {
      setValidInput02(true);
    }
    else
    {
      setValidInput02(false);
    }
  }

  const onSubmit01 = (e) =>
  {
    if(confirmationResult && e.target.verificationCode.value)
    {
      dispatch(SignIn(confirmationResult, e.target.verificationCode.value));
    }
    e.preventDefault();
  }

  return(
    <React.Fragment>
      <div className = "h-screen bg-gray-700 opacity-75 flex flex-col justify-center items-center">
        <div className = "lg:w-2/5 md:w-1/2 w-11/12 bg-white p-10 rounded-lg shadow-lg ">
          <h1 className = "text-center text-4xl mb-6 text-gray-900 font-semibold font-mono">APORTE VIAL</h1>
         
          <form className = "min-w-full"  onSubmit = {(e) => onSubmit01(e)}>
            <div>
            <label className = "text-center text-2xl text-gray-800 font-semibold block my-3 text-base font-mono uppercase" htmlFor = "phonenumber">ingresar</label>
              <label className = "text-center text-xl text-gray-800 font-semibold block my-3 text-base font-mono uppercase" htmlFor = "phonenumber">código verificación</label>
              <input 
                className = "text-center text-2xl w-full bg-gray-100 px-4 py-2 rounded-lg focus:outline-none" 
                type = "text" 
                name = "verificationCode"
                id = "verificationCode"
                placeholder = "######"
                //disabled = {(confirmationResult !== null) ? false : true}
                onChange ={e => onChange01(e)}
              />
              
              {!code &&
                <label className = "text-center text-red-500 font-bold block my-1 ml-3 text-base font-mono">INGRESAR CÓDIGO</label>
              }

              {!validInput02 && code &&
                <label className = "text-center text-red-500 font-bold block my-1 ml-3 text-base font-mono">DEBE CONTENER 6 NÚMEROS</label>
              } 

              {validInput02 && code &&
                <label className = "text-center text-green-500 font-bold block my-1 ml-3 text-base font-mono">CÓDIGO VÁLIDO PARA VERIFICAR</label>
              } 
            </div>          

            <button 
              type = "submit"
              className = {(confirmationResult !== null && validInput02) ? "w-full mt-6 bg-sky-600 hover:bg-sky-900 rounded-lg px-4 py-2 text-lg text-white tracking-wide font-semibold font-mono" : "w-full mt-6 bg-gray-600 rounded-lg px-4 py-2 text-lg text-gray-200 tracking-wide font-semibold font-mono"} 
              disabled = {(confirmationResult !== null && validInput02) ? false : true}
            >
              INGRESAR CÓDIGO
            </button>
          </form>
        </div>

        <Link 
          className = 'flex items-center justify-evenly my-5'
          to        = {'/'}
        >
          <button
            className ="text-sm lg:text-base bg-transparent hover:bg-sky-500 text-white font-semibold hover:text-white py-2 px-4 border-2 border-white hover:border-transparent rounded"
          >
            INICIO
          </button>
        </Link>
      </div>
    </React.Fragment>
  );
}

export default LogInPage;