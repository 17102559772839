//Dependencies
import React from 'react';
import { Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
//Actions
//import { infoNotification } from '../../../store/actions/NotificationsActions';

/* MODAL DE ESTADÍSTICAS - SOLO VIEW */
const Modal04 = (props) =>
{
  const { height,  width } = props; 
  const dispatch = useDispatch();

  const modal04 = useSelector(state => state.modals.modal04);
  const repair = useSelector(state => state.statistics.repair);
  const nonrepair = useSelector(state => state.statistics.nonrepair);
  const repeated = useSelector(state => state.statistics.repeated);

  const month = useSelector(state => state.statistics.month);
  const year = useSelector(state => state.statistics.year);

  const getDates = (month) =>
  {
    let text;
    switch(month) {
      case 1:
        text = 'ENERO'
        break;
      case 2:
        text = 'FEBRERO'
        break;
      case 3:
        text = 'MARZO'
        break;
      case 4:
        text = 'ABRIL'
        break;
      case 5:
        text = 'MAYO'
        break;
      case 6:
        text = 'JUNIO'
        break;
      case 7:
        text = 'JULIO'
        break;
      case 8:
        text = 'AGOSTO'
        break;
      case 9:
        text = 'SEPTIEMBRE'
        break;
      case 10:
        text = 'OCTUBRE'
        break;
      case 11:
        text = 'NOVIEMBRE'
        break;
      case 12:
        text = 'DICIEMBRE'
        break;
      default:
        // code block
    }

    return text;
  }

  return(
    <Modal
      title     = {null}
      closable  = {false}
      visible   = {modal04}
      onCancel  = {() => dispatch({type: 'SET_MODAL_04', modal04 : false})}
      width     = {Math.min(height, width)}
      footer    = {null}
      style     = {{ top: 20 }}
      bodyStyle = {{ borderRadius: '0px 0px 15px 15px !important'}}
      className = 'rounded-lg'
    >
      <div className = "max-w-md mx-auto ">
        <div className = "divide-y divide-gray-300/50">
          <p className = "flex justify-center text-xl font-semibold font-mono">ESTADÍSTICAS</p>         

          <div className = "w-full max-w-2xl justify-center">

            <h3 className = "flex justify-center text-3xl text-gray-700 font-semibold leading-tight mt-2 mb-1">{`${repair + nonrepair} DAÑOS REPORTADOS`}</h3>
            
            {year &&
              <h3 className = "flex justify-center text-2xl text-gray-700 font-semibold leading-tight my-1">{`DESDE ${getDates(month)} DE ${year}`}</h3>
            }

            <div  className = "-mx-1 md:flex">
              <div className = "w-full md:w-1/3 px-2">
                <div className = "rounded-lg shadow-lg mb-4 border border-black">
                  <div className = "rounded-lg bg-white shadow-lg md:shadow-xl relative overflow-hidden">
                    <div className = "px-3 pt-4 pb-2 text-center relative z-10">
                      <h4 className = "text-sm uppercase text-gray-900 leading-tight font-semibold font-mono">DAÑOS SIN REPARAR</h4>
                      <h3 className = "text-3xl text-gray-700 font-semibold leading-tight my-3">{nonrepair}</h3>
                      <p className = "text-base text-red-500 leading-tight">{Math.floor((nonrepair / (nonrepair + repair)) * 10000, 4) / 100}%</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className = "w-full md:w-1/3 px-2">
                <div className = "rounded-lg shadow-lg mb-4 border border-black">
                  <div className = "rounded-lg bg-white shadow-lg md:shadow-xl relative overflow-hidden">
                    <div className = "px-3 pt-4 pb-2 text-center relative z-10">
                      <h4 className = "text-sm uppercase text-gray-900 leading-tight font-semibold font-mono">DAÑOS REPARARADOS</h4>
                      <h3 className = "text-3xl text-gray-700 font-semibold leading-tight my-3">{repair}</h3>
                      <p className = "text-base text-green-500 leading-tight">{Math.floor((repair / (nonrepair + repair)) * 10000, 4) / 100}%</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className = "w-full md:w-1/3 px-2">
                <div className = "rounded-lg shadow-lg mb-4 border border-black">
                  <div className = "rounded-lg bg-white shadow-lg md:shadow-xl relative overflow-hidden">
                    <div className = "px-3 pt-4 pb-2 text-center relative z-10">
                      <h4 className = "text-sm uppercase text-gray-900 leading-tight font-semibold font-mono">MÚLTIPLES REPARACIONES</h4>
                      <h3 className = "text-3xl text-gray-700 font-semibold leading-tight my-3">{repeated}</h3>
                      <p className = "text-base text-red-500 leading-tight">{repair > 0 ? Math.floor((repeated / (repair)) * 10000, 4) / 100 : 0 }%</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className = "pt-4 text-base font-semibold flex justify-center">
            <button 
              className = "bg-transparent hover:bg-sky-500 text-gray-800 font-semibold hover:text-white py-2 px-4 border-2 border-gray-500 hover:border-transparent rounded"
              onClick   = {() => dispatch({type: 'SET_MODAL_04', modal04 : false})}  
            >
              SALIR
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default Modal04;