//Dependecies
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isMobileOnly } from 'react-device-detect';
//Actions
import { VerifyingCurrentUser } from '../../store/actions/AuthActions';
//Pages
import PhoneOnlyPage from '../pages/PhoneOnlyPage';
import AuthCheckingPage from '../pages/AuthCheckingPage';
//Routers
import ReportRouter from '../routers/ReportRouter';
import LogInRouter from './LogInRouter';

const AppRouter = () =>
{
  const dispatch = useDispatch();

  const checking = useSelector(state => state.auth.checking);
  const connected = useSelector(state => state.auth.connected);  

  /* CHECK CURRENT USER */
  useEffect(() => 
  {
    dispatch(VerifyingCurrentUser());
  }, [dispatch]);

  if(!isMobileOnly) return(
    <PhoneOnlyPage/>
  );

  if(checking) return(
    <AuthCheckingPage/>
  );

  if(!checking && !connected) return(
    <LogInRouter/>
  );

  return(
    <React.Fragment>
      <ReportRouter/>
    </React.Fragment>
  );
}

export default AppRouter;