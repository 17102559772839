const initState =
{
  loading   : true,
  uploading : false,
}

const appReducer = (state = initState, action) =>
{
  switch (action.type)
  {    
    case 'LOADING':
      return {
        ...state,
        loading: action.loading
      };
      
    case 'UPLOADING':
      return {
        ...state,
        uploading: action.uploading
      }; 

    default:
      return state; 
  }
}

export default appReducer;