//Dependencies
import React, { useState, useRef, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import Webcam from "react-webcam";

const FACING_MODE_ENVIRONMENT = "environment";

const CameraCapture = () => 
{  
  const dispatch = useDispatch();
  const [facingMode] = useState(FACING_MODE_ENVIRONMENT);
  const webcamRef = useRef(null);

  const videoConstraints = 
  {
    width       : window.innerWidth * 0.95 > 300 ? 300 : window.innerWidth * 0.95,
    height      : window.innerWidth * 0.95 > 300 ? 300 : window.innerWidth * 0.95,
    facingMode  : FACING_MODE_ENVIRONMENT
  };

  const capture = useCallback(() => 
    {
      const imageSrc = webcamRef.current.getScreenshot();

      dispatch({type: 'SET_REPORT_SNAPSHOT', reportSnapshot : imageSrc});
    },
    [webcamRef, dispatch]
  );

  return (
    <React.Fragment>
      <div
        style = {{ width: window.innerWidth * 0.9, height: window.innerWidth * 0.9, maxWidth: 400, maxHeight: 400, margin: 'auto'}}
      >
        <Webcam
          audio = {false}
          height = {500}
          width = {500}
          ref = {webcamRef}
          screenshotFormat = "image/jpeg"        
          videoConstraints =
          {{
            ...videoConstraints,
            facingMode
          }}
        />
      </div>

      <div className = 'flex items-center justify-evenly w-full mt-3 mx-0 max-w-[400px]'>
        <div 
          className = "m-0 text-white text-center inline-flex items-center justify-center text-base w-10 h-10 md:text-xl md:w-12 md:h-12 lg:text-xl lg:w-12 lg:h-12 shadow-lg rounded-full bg-sky-600 hover:bg-sky-900 cursor-pointer"
          onClick = {() => capture()} 
        >
          <i className = "fas fa-camera text-white m-0"></i>
        </div>
        
        {/* 
        <div 
          className = "m-0 text-white text-center inline-flex items-center justify-center text-base w-10 h-10 md:text-xl md:w-12  md:h-12 lg:text-xl lg:w-12 lg:h-12 shadow-lg rounded-full bg-gray-800 hover:bg-sky-600 cursor-pointer"
          onClick = {() => deleteCapture()} 
        >
          <i className = "fas fa-trash text-white m-0"></i>
        </div>
        */}
      </div>
    </React.Fragment>
  );
};

export default CameraCapture;