//Dependencies
import React from 'react';
import { Affix } from 'antd';
import { LayerFilledIcon } from '../../../icons/icons';
import { AimOutlined, EnvironmentOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';

//Actions
import { GetLiveLocation } from '../../../store/actions/MapActions';

/* SIDEMENU */
const Sidemenu03 = (props) =>
{  
  const { height, layers, liveLocation } = props; 
  const dispatch = useDispatch();

  const reCenter = () =>
  {
    dispatch({type: 'SET_CENTER', center : liveLocation});
    dispatch({type: 'SET_LOCATION', location : liveLocation});

    setTimeout(() => 
    {
      dispatch({type: 'SET_ZOOM', zoom : 19});
    }, 500);
  }
  
  return(
    <Affix 
      style = {{ position: "fixed", bottom: height, right: 10, zIndex : 1000}}
    >
      <div>
        {!liveLocation &&
          <div 
            className = "flex items-center justify-center text-white p-3 text-center mb-5 md:w-16 md:h-16 w-12 h-12 shadow-lg rounded-full cursor-pointer bg-gray-800 hover:bg-sky-600"
            onClick   = {() => dispatch(GetLiveLocation())}
          >
            <EnvironmentOutlined  className = ' text-xl lg:text-2xl flex cursor-pointer '/>
          </div>
        }

        {liveLocation &&
          <div 
            className = "flex items-center justify-center text-white p-3 text-center mb-5 md:w-16 md:h-16 w-12 h-12 shadow-lg rounded-full cursor-pointer bg-gray-800 hover:bg-sky-600"
            onClick   = {() => reCenter()}
          >
            <AimOutlined  className = ' text-xl lg:text-2xl flex cursor-pointer '/>
          </div>
        }

        <div 
          className = "flex items-center justify-center text-white p-3 text-center mb-5 md:w-16 md:h-16 w-12 h-12 shadow-lg rounded-full cursor-pointer bg-gray-800 hover:bg-sky-600"
          onClick   = {() => dispatch({type: 'SET_LAYERS', layers : !layers})}
        >
          <LayerFilledIcon className = ' text-xl lg:text-2xl flex cursor-pointer '/>
        </div>
      </div>
    </Affix>
  );
}

export default Sidemenu03;