const initState =
{
  checking            : true,
  connected           : false,
  user                : null,
  confirmationResult  : null,
}

const authReducer = (state = initState, action) =>
{
  switch (action.type)
  {    
    case 'SET_AUTH_CHECKING':
      return {
        ...state,
        checking: action.checking
      };

    case 'SET_AUTH_CONNECTED':
      return {
        ...state,
        connected: action.connected
      };

    case 'SET_CONFIRMATION_RESULT':
      return {
        ...state,
        confirmationResult: action.confirmationResult
      };

    case 'SET_AUTH_USER':
      return {
        ...state,
        user: action.user
      };

    default:
      return state; 
  }
}

export default authReducer;