//Dependecies
import React from 'react';

const AuthCheckingPage = () =>
{
  return(
    <React.Fragment>
      <div className = "fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-gray-700 opacity-75 flex flex-col items-center justify-center">
        <div className = "loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4"></div>
        <h2 className = "text-center text-white text-xl font-semibold font-mono">
          VERIFICANDO CONEXIÓN...
        </h2>
        <p className = "lg:w-1/3 text-center text-white font-mono">
          Se está verificando si está LOGUEADO en la aplicación.
        </p>
      </div>
    </React.Fragment>
  );
}

export default AuthCheckingPage;