//Dependecies
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
//Actions
import { GetVerificationId } from '../../store/actions/AuthActions';

const VerificationPage = () =>
{
  const dispatch = useDispatch();
  const [validInput01, setValidInput01] = useState(false);
  const [phone, setPhone] = useState();

  const onChange01 = (e) =>
  {
    var phoneFormat = /\(?([0]{1})\)?([0-9]{9})/;
    var phoneLength = /^\d{10}$/;

    setPhone(e.target.value);

    if(e.target.value.match(phoneFormat) && e.target.value.match(phoneLength))
    {
      setValidInput01(true);
    }
    else
    {
      setValidInput01(false);
    }
  }

  const onSubmit01 = (e) =>
  {
    dispatch(GetVerificationId(e.target.phonenumber.value));
    
    e.preventDefault();
  }

  return(
    <React.Fragment>
      <div className = "h-screen bg-gray-700 opacity-75 flex flex-col justify-center items-center">
        <div className = "lg:w-2/5 md:w-1/2 w-11/12 bg-white p-10 rounded-lg shadow-lg ">
          <h1 className = "text-center text-4xl mb-6 text-gray-900 font-semibold font-mono">APORTE VIAL</h1>
          <form className = "min-w-full" onSubmit = {(e) => onSubmit01(e)}>
            <div>
              <label className = "text-center text-2xl text-gray-800 font-semibold block my-3 text-base font-mono uppercase" htmlFor = "phonenumber">ingresar</label>
              <label className = "text-center text-xl text-gray-800 font-semibold block my-3 text-base font-mono uppercase" htmlFor = "phonenumber">Número de Celular</label>
              <input 
                className = "text-center text-2xl w-full bg-gray-100 px-4 py-2 rounded-lg focus:outline-none" 
                type = "text" 
                name = "phonenumber" 
                id = "phonenumber" 
                placeholder = "ex: 0985323724"
                onChange ={e => onChange01(e)}
              />

              {!phone &&
                <label className = "text-center text-red-500 font-bold block my-1 ml-3 text-base font-mono">INGRESAR NÚMERO</label>
              }

              {!validInput01 && phone &&
                <label className = "text-center text-red-500 font-bold block my-1 ml-3 text-base font-mono">NÚMERO NO SE PARECE A EJEMPLO</label>
              }

              {validInput01 && phone &&
                <label className = "text-center text-green-500 font-bold block my-1 ml-3 text-base font-mono">NÚMERO VÁLIDO PARA VERIFICAR</label>
              }              
            </div>

            <label className = "text-center mt-8 text-center text-xl text-gray-800 font-semibold block my-3 text-base font-mono uppercase" htmlFor = "phonenumber">se enviará mensaje con código de verificación a su celular</label>

            <button 
              type = "submit"
              className = {!validInput01 ? "w-full mt-2 bg-gray-600 rounded-lg px-4 py-2 text-lg text-gray-200 tracking-wide font-semibold font-mono uppercase" : "w-full mt-2 bg-sky-600 hover:bg-sky-900 rounded-lg px-4 py-2 text-lg text-white tracking-wide font-semibold font-mono uppercase"} 
              disabled = {!validInput01 ? true : false}
            >
              verificar número
            </button>
          </form>

          <div id = "recaptcha-container" className = 'my-5 flex justify-center '></div>          
        </div>

        <Link 
          className = 'flex items-center justify-evenly my-5'
          to        = {'/'}
        >
          <button
            className ="text-sm lg:text-base bg-transparent hover:bg-sky-500 text-white font-semibold hover:text-white py-2 px-4 border-2 border-white hover:border-transparent rounded"
          >
            INICIO
          </button>
        </Link>
      </div>
    </React.Fragment>
  );
}

export default VerificationPage;