export const shuffleArrayFunction = (array) =>
{  
  var i = array.length,
  j = 0,
  temp = null;

  while (i > 0) 
  {
    j = Math.floor(Math.random() * i--);
    temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }

  return array; 
}

export const shuffleArrayFunctionExceptFirst = (array) =>
{
  console.log(array);
  
  var i = array.length,
  j = 0,
  temp = null;

  while (i > 0) 
  {
    j = Math.floor(Math.random() * i--);
    temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }

  var index = array.indexOf('ad01');
  if (index !== -1) 
  {
    array.splice(index, 1);
  }

  console.log(array);
  return array; 
}

export const PointInCircle = (point, location, radius)  =>
{
  if(!point || !location) return false;

  let isInside = false;
  let x0 = parseFloat(point[0]);
  let y0 = parseFloat(point[1]);

  let x1 = parseFloat(location[0]);
  let y1 = parseFloat(location[1]);

  if ((x1 - x0) * (x1 - x0) + (y1 - y0) * (y1 - y0) <= (radius/100000) * (radius/100000)) 
  {
    isInside = true;
  };

  return isInside;
}