//Dependencies
import React, { useState } from 'react';
import Webcam from "react-webcam";
//Database
const FACING_MODE_ENVIRONMENT = "environment";

const CameraCapture = ({webcamRef}) => 
{ 
  const [facingMode] = useState(FACING_MODE_ENVIRONMENT);

  const videoConstraints = 
  {
    width       : window.innerWidth,
    height      : window.innerWidth,
    facingMode  : FACING_MODE_ENVIRONMENT
  };

  return (
    <React.Fragment>
      <div
        style = {{ width: window.innerWidth, height: window.innerWidth, maxWidth: window.innerWidth, maxHeight: window.innerWidth, margin: 'auto'}}
      >
        <Webcam
          audio = {false}
          height = {500}
          width = {500}
          ref = {webcamRef}
          screenshotFormat = "image/jpeg"        
          videoConstraints =
          {{
            ...videoConstraints,
            facingMode
          }}
        />
      </div>
    </React.Fragment>
  );
};

export default CameraCapture;