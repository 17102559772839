//Dependecies
import { combineReducers } from 'redux';
import { firebaseReducer } from 'react-redux-firebase';
import { reducer as toastrReducer} from 'react-redux-toastr';
//Reducers
import appReducer from './AppReducer';
import authReducer from './AuthReducer';
import drawerReducer from './DrawersReducer';
import mapReducer from './MapReducer';
import markersReducer from './MarkersReducer';
import modalsReducer from './ModalsReducer';
import reportReducer from './ReportReducer';
import statisticsReducer from './StatisticsReducer';

const RootReducer = combineReducers(
{
  app             : appReducer,
  auth            : authReducer,
  drawers         : drawerReducer,
  map             : mapReducer,
  markers         : markersReducer,
  modals          : modalsReducer,
  reports         : reportReducer,
  statistics      : statisticsReducer,
  
  firebase        : firebaseReducer,
  toastr          : toastrReducer,
});

export default RootReducer;