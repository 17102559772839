const initState =
{
  drawer01      : false,
  drawer02      : false,
}

const drawersReducer = (state = initState, action) =>
{
  switch (action.type)
  {    
    case 'SET_DRAWER_01':
      return {
        ...state,
        drawer01: action.drawer01
      };

    case 'SET_DRAWER_02':
      return {
        ...state,
        drawer02: action.drawer02
      };

    default:
      return state; 
  }
}

export default drawersReducer;