//Dependencies
import React from 'react';
import { Link } from 'react-router-dom';
import { Popconfirm, Popover } from 'antd';
import { FilterOutlined, HomeOutlined, SendOutlined } from '@ant-design/icons';
import { InfoFilledIcon } from '../../../icons/icons';
import { useDispatch } from 'react-redux';
//Components
import Popover02 from '../popovers/Popover02';
//Scripts
import { PointInCircle } from '../../../scripts/Helpers';
//Actions
import { UploadAporte } from '../../../store/actions/ReportActions';

/* MENU BAR */
const Menu04 = (props) =>
{
  const { filter, height, location, user , liveLocation, damageType, snapshot, setSnapshot, capture} = props;
  const dispatch = useDispatch();
  
  const sendReport = () =>
  {
    let date = new Date().getTime();

    let data =
    {
      date            : date,
      image           : snapshot,
      location        : location,
      user            : user,
      type            : damageType,
    }

    dispatch(UploadAporte(data));
    setSnapshot(null);
  }

  return(
    <footer 
      className = {` fixed bottom-0 flex justify-center items-center bg-transparent text-gray-800 w-screen z-[1000] `}
      style = {{height : height}}
    >      
      <Link 
        className = "flex items-center justify-center text-white p-3 text-center md:w-16 md:h-16 w-12 h-12 shadow-lg rounded-full cursor-pointer bg-gray-800 hover:bg-sky-600 mx-3"
        to        = {'/'}
      >
        <HomeOutlined  className = ' text-xl lg:text-2xl flex cursor-pointer '/>
      </Link>

      <div 
        className = "flex items-center justify-center text-white p-3 text-center md:w-16 md:h-16 w-12 h-12 shadow-lg rounded-full cursor-pointer bg-gray-800 hover:bg-sky-600 mx-3"
        onClick   = {() => dispatch({type: 'SET_MODAL_03', modal03 : true})}
      >
        <InfoFilledIcon  className = ' text-xl lg:text-2xl flex cursor-pointer '/>
      </div>     

      <Popover
        content = 
        {
          () => <Popover02
                  filter = {filter}
                />
        }
        trigger = "click"
      >
        <div 
          className = "flex items-center justify-center text-white p-3 text-center md:w-16 md:h-16 w-12 h-12 shadow-lg rounded-full cursor-pointer bg-gray-800 hover:bg-sky-600 mx-3"
        >
          <FilterOutlined  className = ' text-xl lg:text-2xl flex cursor-pointer '/>
        </div>
      </Popover>      

      {!snapshot &&
        <div 
          className = "flex items-center justify-center text-white p-3 text-center md:w-16 md:h-16 w-12 h-12 shadow-lg rounded-full cursor-pointer bg-gray-800 hover:bg-sky-600 mx-3"
          onClick   = {() => capture()}
        >
          <i className = " fas fa-camera text-xl lg:text-2xl flex cursor-pointer"></i>
        </div>
      }

      {snapshot &&
        <div 
          className = "flex items-center justify-center text-white p-3 text-center md:w-16 md:h-16 w-12 h-12 shadow-lg rounded-full cursor-pointer bg-red-500 hover:bg-sky-600 mx-3"
          onClick   = {() => setSnapshot(null)}
        >
          <i className = " fas fa-trash text-xl lg:text-2xl flex cursor-pointer"></i>
        </div>
      }      

      {PointInCircle(location ,liveLocation, 30) && damageType && snapshot &&
        <Popconfirm
          title       = {'¿Quieres Enviar APORTE?'}
          placement   = "topRight"
          onConfirm   = {() => sendReport()}
          okText      = "Si"
          cancelText  = "No"
        >
          <div 
            className = "flex items-center justify-center text-white p-3 text-center md:w-16 md:h-16 w-12 h-12 shadow-lg rounded-full cursor-pointer bg-gray-800 hover:bg-sky-600 mx-3"
          >
            <SendOutlined
              className = ' text-xl lg:text-2xl flex cursor-pointer '
            />
          </div>
        </Popconfirm>
      }

      {PointInCircle(location ,liveLocation, 30) && damageType && !snapshot &&
        <div 
          className = "flex items-center justify-center text-black p-3 text-center md:w-16 md:h-16 w-12 h-12 shadow-lg rounded-full cursor-pointer bg-gray-300 cursor-default mx-3"
        >
          <SendOutlined  className = ' text-xl lg:text-2xl flex cursor-pointer '/>
        </div>
      }

      {PointInCircle(location ,liveLocation, 30) && !damageType &&
        <div 
          className = "flex items-center justify-center text-black p-3 text-center md:w-16 md:h-16 w-12 h-12 shadow-lg rounded-full cursor-pointer bg-gray-300 cursor-default mx-3"
        >
          <SendOutlined  className = ' text-xl lg:text-2xl flex cursor-pointer '/>
        </div>
      }

      {(!PointInCircle(location ,liveLocation, 30)) && 
        <div 
          className = "flex items-center justify-center text-black p-3 text-center md:w-16 md:h-16 w-12 h-12 shadow-lg rounded-full cursor-pointer bg-gray-300 cursor-default mx-3"
        >
          <SendOutlined  className = ' text-xl lg:text-2xl flex cursor-pointer '/>
        </div>
      }


    </footer>
  );
}

export default Menu04;