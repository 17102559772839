//Dependencies
import React from 'react';
import { Modal, Popconfirm } from 'antd';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
//import { EnvironmentOutlined, FilterOutlined, HomeOutlined, GroupOutlined, UngroupOutlined, PlusOutlined } from '@ant-design/icons';
import { AimOutlined, EnvironmentOutlined, FilterOutlined, HomeOutlined, PlusOutlined } from '@ant-design/icons';
import { InfoFilledIcon } from '../../../icons/icons';
//Actions
import { SignOut } from '../../../store/actions/AuthActions';

/* MODAL DE INFORMACIÓN - APP */
const Modal03 = (props) =>
{
  //const { group, height,  width } = props; 
  const { height,  width } = props; 
  const dispatch = useDispatch();
  const modal03 = useSelector(state => state.modals.modal03);

  return(
    <Modal
      title     = {null}
      closable  = {false}
      visible   = {modal03}
      onCancel  = {() => dispatch({type: 'SET_MODAL_03', modal03 : false})}
      width     = {Math.min(height, width)}
      footer    = {null}
      style     = {{ top: 20 }}
      bodyStyle = {{ borderRadius: '0px 0px 15px 15px !important'}}
      className = 'rounded-lg'
    >
      <div className = "max-w-md mx-auto px-[24px]">
        <div className = "divide-y divide-gray-300/50">
          <p className = "flex justify-center text-xl font-semibold font-mono">INSTRUCCIONES</p>

          <div className = "py-2 text-base text-gray-600 ">
            <ul className = "space-y-4">
              <li className = "flex items-center">
                <div className = " text-center inline-flex items-center justify-center w-9 h-9 rounded-full bg-gray-200 text-gray-500 border-2 border-gray-500">
                  <HomeOutlined />
                </div>
                <p className = "ml-4 mb-0">
                  <span className = "text-sm md:text-base lg:text-base font-extrabold font-mono text-gray-900">IR A INICIO</span>
                </p>
              </li>  

              <li className = "flex items-center">
                <div className = " text-center inline-flex items-center justify-center w-9 h-9 rounded-full bg-gray-200 text-gray-500 border-2 border-gray-500">
                  <EnvironmentOutlined />
                </div>
                <p className = "ml-4 mb-0">
                  <span className = "text-sm md:text-base lg:text-base font-extrabold font-mono text-gray-900">UBICACIÓN CON GPS Y POR SECTOR</span>
                </p>
              </li>              

              <li className = "flex items-center">
                <div className = " text-center inline-flex items-center justify-center w-9 h-9 rounded-full bg-gray-200 text-gray-500 border-2 border-gray-500">
                  <FilterOutlined />
                </div>
                <p className = "ml-4 mb-0">
                  <span className = "text-sm md:text-base lg:text-base font-extrabold font-mono text-gray-900">FILTRO POR TIPO DE APORTE</span>
                </p>
              </li>

              <li className = "flex items-center">
                <div className = " text-center inline-flex items-center justify-center w-9 h-9 rounded-full bg-gray-200 text-gray-500 border-2 border-gray-500">
                  <AimOutlined />
                </div>
                <p className = "ml-4 mb-0">
                  <span className = "text-sm md:text-base lg:text-base font-extrabold font-mono text-gray-900">CENTRA UBICACIÓN DE APORTE</span>
                </p>
              </li>

              <li className = "flex items-center">
                <div className = " text-center inline-flex items-center justify-center w-9 h-9 rounded-full bg-gray-200 text-gray-500 border-2 border-gray-500">
                  <PlusOutlined />
                </div>
                <p className = "ml-4 mb-0">
                  <span className = "text-sm md:text-base lg:text-base font-extrabold font-mono text-gray-900">AGREGAR APORTE</span>
                </p>
              </li>

              <li className = "flex items-center">
                <div className = " text-center inline-flex items-center justify-center w-9 h-9 rounded-full bg-gray-200 text-gray-500 border-2 border-gray-500">
                  <i className = "fas fa-layer-group"></i>
                </div>
                <p className = "ml-4 mb-0">
                  <span className = "text-sm md:text-base lg:text-base font-extrabold font-mono text-gray-900">CAMBIAR ENTRE SATÉLITE Y MAPA</span> 
                </p>
              </li>
              
              <li className = "flex items-center">
                <div className = " text-center inline-flex items-center justify-center w-9 h-9 rounded-full bg-gray-200 text-gray-500 border-2 border-gray-500">
                  <InfoFilledIcon />
                </div>
                <p className = "ml-4 mb-0">
                  <span className = "text-sm md:text-base lg:text-base font-extrabold font-mono text-gray-900">SE ABRE ESTA VENTANA</span> 
                </p>
              </li>             
            </ul>
            
            <div className = 'flex items-center justify-evenly'>
              <Link 
                className = 'flex items-center justify-evenly'
                target    = "_blank" 
                to        = {'/'}
              >
                <button className ="text-xs lg:text-base bg-transparent hover:bg-sky-500 text-gray-800 font-semibold hover:text-white py-2 px-4 border-2 border-gray-500 hover:border-transparent rounded">
                  HOME PAGE
                </button>
              </Link>

              <Link 
                className = 'flex items-center justify-evenly'
                target    = "_blank" 
                to        = {'/info'}
              >
                <button className ="text-xs lg:text-base bg-transparent hover:bg-sky-500 text-gray-800 font-semibold hover:text-white py-2 px-4 border-2 border-gray-500 hover:border-transparent rounded">
                  INFO
                </button>
              </Link>

              <Popconfirm
                title       = {'¿Quieres Terminar tu Sesión?'}
                placement   = "topRight"
                onConfirm   = {() => dispatch(SignOut())}
                okText      = "Si"
                cancelText  = "No"
              >
                <button className ="text-xs lg:text-base bg-transparent hover:bg-sky-500 text-gray-800 font-semibold hover:text-white py-2 px-4 border-2 border-gray-500 hover:border-transparent rounded">
                  CERRAR SESIÓN
                </button>   
              </Popconfirm>              
            </div>
          </div>

          <div className = "pt-4 text-base font-semibold flex justify-center">
            <button 
              className = "bg-transparent hover:bg-sky-500 text-gray-800 font-semibold hover:text-white py-2 px-4 border-2 border-gray-500 hover:border-transparent rounded"
              onClick   = {() => dispatch({type: 'SET_MODAL_03', modal03 : false})}  
            >
              SALIR
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default Modal03;