const bizlist01 =
{    
  biz01 :
  {
    phonenumber   : '+593985323724',
    biz           : 'entorno',
    bizLogo       : '',
    verified      : true,
    master        : true,
  },
}

export default bizlist01;

//tipos de negocios:
/*
  master - tiene acceso a todo



*/
