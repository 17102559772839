//Dependecies
import React from 'react';
import { useSelector } from 'react-redux';
//Pages
import VerificationPage from '../pages/VerificationPage';
import LogInPage from '../pages/LogInPage';

const LogInRouter = () =>
{
  const confirmationResult = useSelector(state => state.auth.confirmationResult);
  
  if(confirmationResult) return(
    <LogInPage
      confirmationResult = {confirmationResult}
    />
  );

  return(
    <React.Fragment>
      <VerificationPage/>
    </React.Fragment>
  );
}

export default LogInRouter;