//Dependecies
import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
//Databases
import dimensions from '../../databases/dimensions';
//Components
import CameraComponent01 from '../components/camera/CameraComponent01';
import Map03 from '../components/maps/Map03';
import Menu02 from '../components/menus/Menu02';
import Menu04 from '../components/menus/Menu04';
import Menu05 from '../components/menus/Menu05';
import Modal03 from '../components/modals/Modal03';
import Sidemenu03 from '../components/sidemenus/Sidemenu03';
//Actions
import { GetMarkersTest } from '../../store/actions/LoadingActions';

const AporteRouter = () =>
{
  const dispatch = useDispatch();

  const loading = useSelector(state => state.app.loading);

  const user = useSelector(state => state.auth.user);

  const liveLocation = useSelector(state => state.map.liveLocation);
  const location = useSelector(state => state.map.location);
  const center = useSelector(state => state.map.center);
  const zoom = useSelector(state => state.map.zoom);
  const layers = useSelector(state => state.map.layers); 
  const group = useSelector(state => state.map.group); 
  const filter = useSelector(state => state.map.filter); 

  const last = useSelector(state => state.markers.last);

  const markersDB = useSelector(state => state.markers.markersDB);

  const [damageType, setDamageType] = useState(null);
  const [snapshot, setSnapshot] = useState(null);

  const webcamRef = useRef(null);
  
  /* DIMENSIONS */
  const height = window.innerHeight;
  const width = window.innerWidth;
  
  /* LOADING MARKERS */
  useEffect(() => 
  {
    dispatch(GetMarkersTest());
    dispatch({type: 'LOADING', loading : false});
  }, [dispatch]);

  const capture = () => 
  {
    const imageScreenshot = webcamRef.current.getScreenshot();

    setSnapshot(imageScreenshot)
  };
  
  
  if(loading) return(
    <Menu02
      height        = {Math.round(dimensions.menu * height / 100)}        
      visible       = {true}
    />
  );

  return(
    <React.Fragment>
      <CameraComponent01
        snapshot        = {snapshot}
        webcamRef       = {webcamRef}
      />

      <Menu05
        setDamageType   = {setDamageType}
      />

      {markersDB && center && zoom && 
        <Map03
          liveLocation  = {liveLocation}
          location      = {location}
          height        = {height - width - 50}
          center        = {center}
          zoom          = {zoom}
          filter        = {filter}
          paddingTop    = {0}
          paddingBottom = {0}
          visible       = {true}
          markers       = {markersDB}
          layers        = {layers}
          group         = {group}
          last          = {last}
        />
      }

      <Modal03
        height        = {Math.round(dimensions.modal01 * height / 100)}
        width         = {Math.round(95 * width / 100)}
      />

      <Sidemenu03
        height        = {Math.round(dimensions.menu * height / 100)}
        layers        = {layers}
        location      = {location}
        liveLocation  = {liveLocation}
      />

      <Menu04
        height        = {Math.round(dimensions.menu * height / 100)}
        filter        = {filter}
        damageType    = {damageType}
        snapshot      = {snapshot}
        setSnapshot   = {setSnapshot}

        location      = {location}
        user          = {user}
        liveLocation  = {liveLocation}

        capture       = {capture}
      />
    </React.Fragment>
  );
}

export default AporteRouter;