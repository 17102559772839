const initState =
{
  repair        : 0,
  nonrepair     : 0,
  repeated      : 0,

  year          : null,
  month         : null
}

const reportReducer = (state = initState, action) =>
{
  switch (action.type)
  {    
    case 'SET_STATISTICS_REPAIR':
      return {
        ...state,
        repair: action.repair
      };

    case 'SET_STATISTICS_NONREPAIR':
      return {
        ...state,
        nonrepair: action.nonrepair
      };

    case 'SET_STATISTICS_REPEATED':
      return {
        ...state,
        repeated: action.repeated
      };

    case 'SET_STATISTICS_YEAR':
      return {
        ...state,
        year: action.year
      };

    case 'SET_STATISTICS_MONTH':
      return {
        ...state,
        month: action.month
      };
    
    default:
      return state; 
  }
}

export default reportReducer;