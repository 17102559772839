import { initializeApp } from 'firebase/app';
import { getStorage } from "firebase/storage";
import firebase from 'firebase/compat/app';
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";

// Initialize Firebase
const firebaseConfig = 
{
  apiKey: "AIzaSyA1YKgXPEwDTAUABZrO6H72BVgCFDJ2OMw",
  authDomain: "bettercityio.firebaseapp.com",
  databaseURL: "https://bettercityio.firebaseio.com",
  projectId: "bettercityio",
  storageBucket: "bettercityio.appspot.com",
  messagingSenderId: "980821407521",
  appId: "1:980821407521:web:0e79618c92b644f405f060",
  measurementId: "G-HL3DTPH32E"
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const storage = getStorage(app);
export const analytics = getAnalytics(app);

export default firebase;