import React from "react";

export default function Drawer({ children, visible, onClose, height }) 
{
  return (
    <main
      className =
      {
        " fixed overflow-hidden bg-gray-900 bg-opacity-60 inset-0 transition ease-in-out " +
        (visible
          ? " transition-opacity opacity-100 duration-500 translate-y-0 "
          : " transition-opacity delay-500 opacity-0 translate-y-full ")
      }
      style = {{ zIndex: 1010 }}
    >
      <section
        style = {{ height: `${height}vh` }}
        className = 
        {
          " w-screen right-0 bottom-0 absolute bg-white bg-opacity-100 shadow-xl delay-400 duration-500 ease-in-out transition-all transform " +
          (visible ? " translate-y-0 " : " translate-y-full ")
        }
      >
        <article
          className = "relative w-screen pb-10 flex flex-col space-y-6 overflow-y-auto h-full"
          id = 'scroller'
        >
          {children}
        </article>
      </section>

      <section
        className = " w-screen h-full cursor-pointer "
        onClick={() => 
        {
          onClose(false);
        }}
      />
    </main>
  );
}