//Dependencies
import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';

/* MENU BAR */
const Menu02 = (props) =>
{
  const { height, visible } = props;
  if(!visible) return null;
  
  return(
    <footer 
      className = {` fixed bottom-0 flex justify-center items-center bg-white text-gray-800 w-screen border-t-2 border-slate-500 drop-shadow-xl z-[1000] `}
      style = {{height : height}}
    >
      <LoadingOutlined className = ' text-3xl lg:text-4xl flex cursor-pointer '/>
    </footer>
  );
}

export default Menu02;