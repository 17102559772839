//Dependencies
import React from 'react';
import { Select } from 'antd';
//Database
import list01 from '../../../databases/list01';

const { Option } = Select;

const Menu05 = ({damageType, setDamageType}) => 
{ 
  return (
    <React.Fragment>
      <div className = 'flex items-center justify-evenly w-full max-w-[500px] border-y-2 border-black	h-[50px]'>
        <div 
          className = "flex-1 w-full h-full text-white text-center inline-flex items-center justify-center text-base md:text-xl md:w-12 md:h-12 lg:text-xl lg:w-12 lg:h-12 shadow-lg bg-sky-600 hover:bg-sky-900 cursor-pointer"
        >
           <Select
              placeholder = 'SELECCIONAR DAÑO' 
              style = {{ width:  window.innerWidth * 0.8}}
              onChange = {(e) => setDamageType(e)}
              defaultValue = {damageType}
            >
              {list01 && Object.keys(list01).map((m1, i) => 
              {
                let info = list01[m1];

                return(
                  <Option key = {i} value = {info.value}>{info.text.toUpperCase()}</Option>                  
                )
              })
            }     
            </Select>
        </div>      
      </div>
    </React.Fragment>
  );
};

export default Menu05;